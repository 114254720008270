import { useState, useEffect, Dispatch, FC, Fragment, SetStateAction } from "react";
// import { useQuery } from 'react-query';
import { createStyles, makeStyles, Theme, Box, FormControl, Grid, InputLabel } from '@material-ui/core';
import { Button, CircularProgress, TextField, Select } from '@mui/material';
import CheckAttr from "../../function/CheckAttr";
import { Alert } from '@material-ui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataPays from "../../interface/DataPays";
import DataBu from "../../interface/DataBu";
import DataClient from "../../interface/DataClient";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        formControl: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        TextField: {
            width: '100%',
        },
        warning: {
            color: theme.palette.primary.light,
        },
        loading: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            }
        },
        messageWarning: {
            color: 'red',
            display: 'flex',
            alignItems: 'center',
        },
    })
);

const showMessageToastError = (): void => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const showMessageToastSuccess = (msg: string): void => {
    toast.success(msg,
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
};

const showMessageWarning = (param: string) => {
    toast.warning(param, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

interface DataIRMClient {
    Client_Libelle: string,
    Client_ID: string
}

interface ClientValidation {
    pays: boolean,
    bu: boolean,
    client: boolean
};

interface ClientRowFormProps {
    client?: DataClient;
    allPays: DataPays[];
    allBus: DataBu[];
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    refresh: boolean;
};

const initialValidationInput: ClientValidation = {
    pays: false,
    bu: false,
    client: false
};

const ClientForm: FC<ClientRowFormProps> = ({
    client,
    allPays,
    allBus,
    setOpenModal,
    setRefresh,
    refresh,
}) => {
    const classes = useStyles();

    const urlApi = process.env.REACT_APP_BACKEND_URL as string;

    const [isClickSave, setIsClickSave] = useState<boolean>(false);
    const [isReceiveClientIRM, setIsReceiveClientIRM] = useState<boolean>(false);
    const [irmClient, setIrmClient] = useState<DataIRMClient[]>([]);
    const [clientToSave, setClientToSave] = useState<DataClient | undefined>(client);
    const [errorInput, setErrorInput] = useState<ClientValidation>(initialValidationInput);

    /* const { isLoading, data } = useQuery('client-irm', () => {
        return axios.get('http://172.25.39.92/rapport_excel/ExportExcel/rapportClientsJson')
    }); */

    useEffect(() => {
        if (CheckAttr(clientToSave?.pays)) {
            setIsReceiveClientIRM(true);
            axios.get(`${urlApi}get/irm/data/${clientToSave?.pays}`).then((res) => {
                Array.isArray(res.data) && setIrmClient(res.data);
            }).catch((error) => {
                console.log(error.message);
            }).finally(() => {
                setIsReceiveClientIRM(false)
            });
        }
    }, [clientToSave?.pays]);

    const handleChange = (e: any): void => {
        const { name, value } = e.target;

        if (name === 'pays' && !CheckAttr(value)) {
            setIrmClient([]);

            setClientToSave({
                ...clientToSave as DataClient,
                id_client_irm: undefined,
                client_irm: undefined
            });
        }

        if (name === 'id_client_irm') {
            const libelleClientIrm = irmClient?.find(cl => cl?.Client_ID === value)?.Client_Libelle;

            setClientToSave({
                ...clientToSave as DataClient,
                [name]: value,
                client_irm: libelleClientIrm
            });
        } else {
            setClientToSave({
                ...clientToSave as DataClient,
                [name]: value
            });
        }
    };

    const checkValidationInput = (): boolean => {
        if (!clientToSave?.pays || !clientToSave?.bu || !clientToSave?.client1) {
            if (!CheckAttr(clientToSave?.pays)) {
                setErrorInput((prev: any) => ({ ...prev, pays: true }));
                showMessageWarning("Merci de remplir le champ Pays !");
            }

            if (!CheckAttr(clientToSave?.bu)) {
                setErrorInput((prev: any) => ({ ...prev, bu: true }));
                showMessageWarning("Merci de remplir le champ Bu !");
            }

            if (!CheckAttr(clientToSave?.client1)) {
                setErrorInput((prev: any) => ({ ...prev, client: true }));
                showMessageWarning("Merci de remplir le champ pour Client !");
            }

            return false;
        }

        return true;
    };

    const handleSave = (): void => {
        // reinitialiser à false les valeurs des attributs qui controle les champs obligatoire
        setErrorInput(initialValidationInput);

        // Control de tout les champs obligatoire
        if (checkValidationInput()) {
            setIsClickSave(true);

            if (clientToSave?.id) {
                // update Société de facturation
                axios.put(`${urlApi}parametrage/client/update`, clientToSave)
                    .then(res => {
                        showMessageToastSuccess(res.data);
                        setOpenModal(false);
                        setRefresh(!refresh);
                    }).catch((e) => {
                        alert(e.message);
                        showMessageToastError();
                    }).finally(() => {
                        setIsClickSave(false);
                    });
            } else {
                //  create new Société de facturation
                axios.post(`${urlApi}parametrage/client/create`, clientToSave)
                    .then((res) => {
                        showMessageToastSuccess(res.data);
                        setOpenModal(false);
                        setRefresh(!refresh);
                    }).catch((e) => {
                        alert(e.message);
                        showMessageToastError();
                    }).finally(() => {
                        setIsClickSave(false);
                    });
            }
        }
    };

    const paysLabel = (
        <Fragment>
            <span>Pays</span>
            <span style={{ color: 'red', fontSize: 20 }}> *</span>
        </Fragment>
    );

    const buLabel = (
        <Fragment>
            <span>Bu</span>
            <span style={{ color: 'red', fontSize: 20 }}> *</span>
        </Fragment>
    );

    const clientLabel = (
        <Fragment>
            <span>Client</span>
            <span style={{ color: 'red', fontSize: 20 }}> *</span>
        </Fragment>
    );

    /**
    * Logique show message validarion forms........................
    */
    const messageValidation = (
        !clientToSave?.pays ||
        !clientToSave?.bu ||
        !clientToSave?.client1
    ) && (
            <Alert variant="filled" severity="error">
                Attention tous les champs en <b>*</b> rouge sont obligatoire, merci de
                remplir SVP !
            </Alert>
        );

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel
                            htmlFor="pays"
                            shrink={clientToSave?.pays !== undefined && true}
                        >
                            {paysLabel}
                        </InputLabel>
                        <Select
                            value={clientToSave?.pays}
                            onChange={handleChange}
                            error={errorInput?.pays}
                            color="warning"
                            inputProps={{
                                name: 'pays',
                            }}
                            native
                        >
                            <option value=""></option>
                            {
                                allPays.length === 0 ? (
                                    <option disabled>Loading ...</option>
                                ) : (allPays.map((item) => (
                                    <option key={item?.id_pays} value={item?.pays1}>
                                        {item?.pays1}
                                    </option>
                                )))
                            }
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel
                            htmlFor="bu"
                            shrink={clientToSave?.bu !== undefined && true}
                        >
                            {buLabel}
                        </InputLabel>
                        <Select
                            value={clientToSave?.bu}
                            onChange={handleChange}
                            error={errorInput?.bu}
                            color="warning"
                            inputProps={{
                                name: 'bu',
                            }}
                            native
                        >
                            <option value=""></option>
                            {
                                allBus.length === 0 ? (
                                    <option disabled>Loading ...</option>
                                ) : (allBus.map((item) => (
                                    <option key={item?.id} value={item?.bu1}>
                                        {item?.bu1}
                                    </option>
                                )))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label={clientLabel}
                            name="client1"
                            color="warning"
                            error={errorInput?.client}
                            value={clientToSave?.client1}
                            autoComplete="none"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: clientToSave?.client1 !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel
                            htmlFor="id_client_irm"
                            shrink={clientToSave?.id_client_irm !== undefined && true}
                        >
                            Client IRM
                        </InputLabel>
                        <Select
                            value={clientToSave?.id_client_irm}
                            onChange={handleChange}
                            color="warning"
                            inputProps={{
                                name: 'id_client_irm',
                            }}
                            native
                        >
                            <option value=""></option>
                            {
                                isReceiveClientIRM ? (
                                    <option disabled>Loading ...</option>
                                ) : (
                                    irmClient.map((item) => (
                                        <option key={item?.Client_ID} value={item?.Client_ID}>
                                            {item?.Client_Libelle}
                                        </option>
                                    )))
                            }
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Code client gesco"
                            name="code_client_gesco"
                            color="warning"
                            value={clientToSave?.code_client_gesco}
                            autoComplete="none"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: clientToSave?.code_client_gesco !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt={3}>
                <Button
                    color="warning"
                    size="large"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    sx={{ textTransform: 'none' }}
                    onClick={handleSave}
                >
                    {!isClickSave ? (
                        'Enregistrer'
                    ) : (
                        <CircularProgress color="inherit" size={18} />
                    )}
                </Button>
            </Box>{' '}
            <br />
            <div>{messageValidation}</div>
        </Box>
    );
}

export default ClientForm;
