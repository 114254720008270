import { Box, Paper, Pagination, InputBase, styled, Stack, Divider, LinearProgress, IconButton, Typography, Breadcrumbs } from '@mui/material';
import { TableHead, TableRow, TableBody, TableContainer, Table, Container, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Fragment, useState, useEffect } from 'react';
import GradingIcon from '@mui/icons-material/Grading';
import PixIcon from '@mui/icons-material/Pix';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { Reducers } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';

export interface DataBu {
    id: number;
    pays: string;
    bu: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Bu = () => {

    const urlApi = process.env.REACT_APP_BACKEND_URL as string;

    const logger = useSelector<Reducers>((state) => state.logger);

    const [selectedFilter, setSelectedFilter] = useState("");
    const [searched, setSearched] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [nb, setNb] = useState(0);
    const [rows, setRows] = useState<DataBu[]>([]);

    useEffect(() => {
        const data = { token: (logger as any)?.token };

        if (searched === '') {
            axios.post(`${urlApi}parametrage/bu/${page}/pays/empty`, data).then((res) => {
                if (res.data.length !== 0) {
                    setRows(res.data[0]);
                    setNb(res.data[1]); 
                }
                else {
                    setNb(0);
                    const tmp =[];
                    setRows(tmp);
                }
                setIsLoading(false);
            });
        } else {
            if (selectedFilter === "bu") {
                axios.post(`${urlApi}parametrage/bu/${page}/bu/${searched}`, data).then((res) => {
                    if (res.data.length !== 0) {
                        setRows(res.data[0]);
                        setNb(res.data[1]);
                    }
                    else {
                        setNb(0);
                        const tmp =[];
                        setRows(tmp);
                    }
                    setIsLoading(false);
                });
            } else {
                axios.post(`${urlApi}parametrage/bu/${page}/pays/${searched}`, data).then((res) => {
                    if (res.data.length !== 0) {
                        setRows(res.data[0]);
                        setNb(res.data[1]);
                    }
                    else {
                        setNb(0);
                        const tmp =[];
                        setRows(tmp);
                    }
                    setIsLoading(false);
                });
            }
        }
    }, [page, searched]);

    const handleChangeFilterBy = event => {
        event.preventDefault();
        setSelectedFilter(event.target.value);
    };

    const handleChange = (event) => {
        event.preventDefault();
        setSearched(event.target.value);
    };

    return (
        <Fragment>
            <Container maxWidth="lg">
                <Box mt={4} mb={2}>
                    <Stack sx={{ position: "relative" }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography
                                variant="h5"
                            >
                                <GradingIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Paramètrage
                            </Typography>
                            <Typography variant="h6">
                                <PixIcon sx={{ position: 'absolute', top: '3px' }} fontSize="medium" />
                                <Box ml={3.5}>Bu</Box>
                            </Typography>
                        </Breadcrumbs>
                        <Typography sx={{ display: 'flex', position: "absolute", right: "2%" }}>
                            <FormControl sx={{ minWidth: 220 }}>
                                <InputLabel id="demo-simple-select-label" color="warning" sx={{ mt: -1 }}>Chercher par ...</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedFilter}
                                    size="small"
                                    color="warning"
                                    label="Age"
                                    onChange={handleChangeFilterBy}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="pays">Pays</MenuItem>
                                    <MenuItem value="bu">Bu</MenuItem>
                                </Select>
                            </FormControl>
                            <InputBase
                                sx={{ ml: 4, flex: 1 }}
                                value={searched}
                                placeholder="Taper quelque chose ..."
                                onChange={handleChange}
                                fullWidth
                            />
                            <IconButton sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Typography>
                    </Stack>
                </Box>
                <Divider sx={{ my: 4 }} />
                <Paper elevation={3}>
                    <TableContainer component={Paper} style={{ marginTop: 20 }}>
                        <Table sx={{ maxHeight: 500 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Pays</StyledTableCell>
                                    <StyledTableCell align="center">Bu</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {isLoading ? (
                                <TableBody>
                                    <StyledTableCell align="center" colSpan={11}>
                                        <LinearProgress color="warning" />
                                        <Box my={2}><LinearProgress color="warning" /></Box>
                                    </StyledTableCell>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {rows.map((row) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell align="center">{row.pays}</StyledTableCell>
                                            <StyledTableCell align="center">{row.bu}</StyledTableCell>
                                        </StyledTableRow>))}
                                </TableBody>)}
                        </Table>
                    </TableContainer>
                </Paper>
                {
                    nb > 7 && (
                        <Box justifyContent="right" display="flex" mt={3} mr={6}>
                            <Stack spacing={2}>
                                <Pagination
                                    count={Math.ceil(nb / 7)}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    hideNextButton={true}
                                    hidePrevButton={true}
                                    defaultPage={page}
                                    onChange={(event, value) => setPage(value)} />
                            </Stack>
                        </Box>)
                }
            </Container>
        </Fragment>
    )
}

export default Bu;

