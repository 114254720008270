import { FC, Dispatch, Fragment, SetStateAction, useState, useCallback } from "react";
import {
    Box,
    Button,
    styled,
    Stack,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';
import DataRef from "../../../../interface/DataRef";
import { Reducers } from "../../../../redux/reducers";
import { useSelector } from 'react-redux';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const showMessageToastError = (): void => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const showMessageToastSuccess = (msg: string): void => {
    toast.success(msg,
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
};

const showMessageWarning = (msg: string) => {
    toast.warning(msg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

interface PaysFacturationRowProps {
    paysFacturation: DataRef;
    refresh: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>,
    setPage: Dispatch<SetStateAction<number>>
}

const PaysFacturationRow: FC<PaysFacturationRowProps> = ({
    paysFacturation,
    refresh,
    setRefresh,
    setPage
}) => {
    const urlApi = process.env.REACT_APP_BACKEND_URL as string;
    const logger = useSelector<Reducers>((state) => state.logger);

    const [isClickSave, setIsClickSave] = useState<boolean>(false);
    const [isClickEdit, setIsClickEdit] = useState<boolean>(false);
    const [isClickDelete, setIsClickDelete] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [errorInputEditValidation, setErrorInputEditValidation] = useState(false);
    const [paysFacturationToEdit, setPaysFacturationToEdit] = useState<DataRef>(paysFacturation);

    const handleChangeInput = (e): void => {
        const { name, value } = e.target;
        setPaysFacturationToEdit({
            ...paysFacturationToEdit as DataRef,
            [name]: value
        });
    };

    // update Société de facturation
    const handleSave = () => {
        if (!paysFacturationToEdit?.libelle || paysFacturationToEdit?.libelle?.length === 0) {
            setErrorInputEditValidation(true);
            showMessageWarning('Merci de remplir le champ !');
        } else {
            setIsClickSave(true);
            setErrorInputEditValidation(false);

            axios.put(`${urlApi}parametrage/paysfacturation/update`, paysFacturationToEdit)
                .then(res => {
                    showMessageToastSuccess(res.data);
                    setRefresh(!refresh);
                }).catch((e) => {
                    alert(e.message);
                    showMessageToastError();
                }).finally(() => {
                    setIsClickSave(false);
                });
        }
    };

    const handleClickDelete = useCallback(() => {
        setIsClickDelete(true);
        axios.delete(`${urlApi}parametrage/paysfacturation/delete/${paysFacturation?.id}`)
            .then((res) => {
                setOpenDeleteDialog(false);
                setPage(1);
                setRefresh(!refresh);
                showMessageToastSuccess("Suppression effectué !");
            })
            .catch((e) => {
                showMessageToastError();
            }).finally(() => {
                setIsClickDelete(false);
            });
    }, []);

    let defaultBtnEdit = isClickEdit ? (
        <Fragment>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                sx={{ textTransform: 'none' }}
                startIcon={<EditIcon />}
                onClick={handleSave}
            >
                {!isClickSave ? (
                    'Enregistrer'
                ) : (
                    <CircularProgress color="inherit" size={18} />
                )}
            </Button>
            <Button
                color="inherit"
                size="small"
                variant="outlined"
                sx={{ textTransform: 'none' }}
                startIcon={<EditIcon />}
                onClick={() => setIsClickEdit(false)}
            >
                Annuler
            </Button>
        </Fragment>
    ) : (
        <Button
            color="primary"
            size="small"
            variant="outlined"
            sx={{ textTransform: 'none' }}
            startIcon={<EditIcon />}
            onClick={() => setIsClickEdit(true)}
        >
            Modifier
        </Button>
    );

    const defaultBtn = (
        <Stack direction='row' spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            {defaultBtnEdit}
            <Button
                color="error"
                size="small"
                variant="outlined"
                sx={{ textTransform: 'none' }}
                startIcon={<DeleteForeverIcon />}
                onClick={() => setOpenDeleteDialog(true)}
            >
                Supprimer
            </Button>
        </Stack>
    );

    const defaultInputToUse = isClickEdit ? (
        <TextField
            error={errorInputEditValidation}
            name="libelle"
            color="warning"
            size="small"
            value={paysFacturationToEdit?.libelle}
            onChange={(e) => handleChangeInput(e)}
            variant="standard"
            InputLabelProps={{ shrink: true }}
        />
    ) : (
        paysFacturation?.libelle
    );

    return (
        <Fragment>
            <StyledTableCell align="center">
                {defaultInputToUse}
            </StyledTableCell>
            <StyledTableCell>
                {defaultBtn}
            </StyledTableCell>

            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Vous êtes sur le point de supprimer de pays de facturation.
                        <Box justifyContent="center" display="flex" mb={-2} >
                            Êtes-vous sûr de vouloir poursuivre cette action ?
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="warning"
                        onClick={() => handleClickDelete()}
                        autoFocus
                    >
                        {isClickDelete ? <CircularProgress color="inherit" size={16} /> : 'Oui'}
                    </Button>
                    <Button color="inherit" onClick={() => setOpenDeleteDialog(false)}>
                        Non
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default PaysFacturationRow;
