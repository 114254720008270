import axios from "axios";
import { useEffect, useState } from "react";
import { Box, Paper, Pagination, styled, Stack, Divider, LinearProgress, IconButton } from '@mui/material';
import { TableHead, TableRow, TableBody, TableContainer, Table, Container, Select, InputLabel, MenuItem } from '@mui/material';
import { Button, TextField, InputAdornment, Grid, Typography, Breadcrumbs, FormControl } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import GradingIcon from '@mui/icons-material/Grading';
import { CircularProgress } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import { DataCout } from "../../../../interface/DataCout";
import ListeCoutRow from "./ListeCoutRow";
import { Reducers } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useStyles } from "../style";
import 'react-toastify/dist/ReactToastify.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
        textAlign: 'center',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// Message d'alerte (Toaster message)
const showToastSuccess = () => {
    toast.success('Mise à jour effectué !', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const showMessageWarning = (param: string) => {
    toast.warning(param, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const ListeCout = () => {
    const logger = useSelector<Reducers>((state) => state.logger);
    const urlApi = process.env.REACT_APP_BACKEND_C_SHARPS_URL as string;
    const urlApi1 = process.env.REACT_APP_BACKEND_URL as string;

    const classes = useStyles();

    const [dataCouts, setDataCouts] = useState<DataCout[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isResetInput, setIsResetInput] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [keyWord, setKeyWord] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalData, setTotalData] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [isClickBtn, setIsClickBtn] = useState<boolean>(false);

    const isSearchMode = (filter.length > 0 && keyWord.length > 2) && true;

    const filtre = isSearchMode ? filter : 'none';
    const search = isSearchMode ? keyWord : 'none';

    const receiveData = () => {
        // Activer un loader dans notre page
        setIsLoading(true);

        // Requete axios pour avoir une liste des Couts
        setIsLoading(true);

        axios.get(`${urlApi}api/cout/list/${filtre}/${search}/${rowsPerPage}/${page}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${(logger as any)?.token}`,
            }
        }).then((resultat) => {
            setTotalData(resultat.data.totalCount);
            Array.isArray(resultat.data.datas) && setDataCouts(resultat.data.datas);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error.message);
        });
    };

    useEffect(() => {
        receiveData();
    }, [refresh, page, rowsPerPage, isResetInput]);

    const updateCoutInSuivirenta = () => {
        setIsClickBtn(true);
        axios.get(`${urlApi1}suivirenta/update/cout`, {
            headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
            },
        }).then((res) => {
            showToastSuccess();
            setIsClickBtn(false);
            setRefresh(!refresh);
        }).catch((error) => {
            console.log(error.message);
            showMessageToastError();
            setIsClickBtn(false);
        });
    };

    const handleChange = (e): void => {
        const { name, value } = e.target;
        name === "filter" && setFilter(value);
        name === "keyWord" && setKeyWord(value);

        if (value.length === 0) {
            setPage(1);
            setIsResetInput(!isResetInput);
        }
    };

    const handleSearch = () => {
        if (isSearchMode) {
            receiveData();
        } else {
            showMessageWarning('SVP! Merci de remplir les Champs de recherche.');
        }
    };

    const pagination = () => {
        return (
            !isLoading && (
                <Stack spacing={4} direction="row">
                    <Stack spacing={2} direction="row">
                        <InputLabel>Lignes par page :</InputLabel>
                        <FormControl variant="standard" sx={{ minWidth: 40 }}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                color="warning"
                                value={rowsPerPage}
                                onChange={(event) => setRowsPerPage(event.target.value as number)}
                                className={classes.rowPerPageInput}
                                defaultValue={5}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    {
                        totalData > rowsPerPage && (
                            <Pagination
                                count={Math.ceil(totalData / rowsPerPage)}
                                color="standard"
                                size="small"
                                showFirstButton={true}
                                showLastButton={true}
                                hideNextButton={true}
                                hidePrevButton={true}
                                siblingCount={0}
                                boundaryCount={1}
                                defaultPage={page}
                                onChange={(event, value) => setPage(value)} />
                        )
                    }
                </Stack>
            )
        );
    };

    return (
        <Container maxWidth="xl">
            <Box mt={4} mb={2}>
                <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                    <Grid item xs={4} sm={6} md={4}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ position: "relative" }}>
                            <Typography variant="h5">
                                <GradingIcon sx={{ position: 'absolute', top: '3px' }} fontSize="inherit" />
                                <Box ml={3.5}>Coûts</Box>
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={4} sm={4} md={5.5}>
                        <Box className={classes.filterInput}>
                            <FormControl sx={{ minWidth: 220 }}>
                                <InputLabel id="filter input" color="warning" sx={{ mt: -1 }}>Chercher par ...</InputLabel>
                                <Select
                                    labelId="filter input"
                                    id="demo-simple-select"
                                    name="filter"
                                    value={filter}
                                    onChange={handleChange}
                                    size="small"
                                    color="warning"
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="pays">Pays</MenuItem>
                                    <MenuItem value="bu">Bu</MenuItem>
                                    <MenuItem value="client">Client</MenuItem>
                                    <MenuItem value="operation">Operation</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2.5}>
                        <Box className={classes.searchInput}>
                            <TextField
                                sx={{ width: "220px" }}
                                name="keyWord"
                                value={keyWord}
                                onChange={handleChange}
                                placeholder="Taper quelque chose ..."
                                size="small"
                                color="warning"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton aria-label="search" edge="end" onClick={handleSearch}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{ mb: 3 }} />
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} >
                <Grid item xs={4} sm={3} md={4}>
                    <Box className={classes.btnUpdate}>
                        <Button
                            variant="outlined"
                            color="warning"
                            size="small"
                            sx={{ textTransform: 'none' }}
                            onClick={updateCoutInSuivirenta}
                        >
                            {!isClickBtn ? 'Mettre à jour les coûts' : <CircularProgress color="inherit" size={16} />}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={4} sm={5} md={8}>
                    <Box className={classes.topPagination} mr={6}>
                        {pagination()}
                    </Box>
                </Grid>
            </Grid>
            <Paper elevation={3}>
                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                    <Table sx={{ maxHeight: 500 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Pays</StyledTableCell>
                                <StyledTableCell>Bu</StyledTableCell>
                                <StyledTableCell>Client</StyledTableCell>
                                <StyledTableCell>Opération</StyledTableCell>
                                <StyledTableCell>Date début</StyledTableCell>
                                <StyledTableCell>Date fin</StyledTableCell>
                                <StyledTableCell>Coût activité</StyledTableCell>
                                <StyledTableCell>Coût formation</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <StyledTableCell colSpan={8}>
                                    <LinearProgress color="warning" />
                                    <Box my={2}><LinearProgress color="warning" /></Box>
                                </StyledTableCell>
                            ) : (
                                dataCouts.length > 0 ? (
                                    dataCouts.map((row) => (
                                        <StyledTableRow key={row.id}>
                                            <ListeCoutRow row={row} />
                                        </StyledTableRow>))
                                ) : (
                                    <StyledTableCell colSpan={8} align="center">
                                        Aucun resultat a été trouvé ...
                                    </StyledTableCell>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            <Box className={classes.bottomPagination} mb={5} ml={5} mt={3}>
                {pagination()}
            </Box>
        </Container>
    )
}

export default ListeCout;
