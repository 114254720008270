import axios from "axios";
import { useEffect, useState } from "react";
import { Box, Paper, Pagination, TextField, styled, Stack, Divider, LinearProgress, IconButton, Typography, Breadcrumbs, InputAdornment } from '@mui/material';
import { TableHead, TableRow, TableBody, TableContainer, Table, Container, FormControl, Select, InputLabel, MenuItem, Grid } from '@mui/material';
import CheckAttr from "../../../function/CheckAttr";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import GradingIcon from '@mui/icons-material/Grading';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SearchIcon from '@mui/icons-material/Search';
import { DataSaisieManager } from "../../../interface/DataSaisieManager";
import HeureAgentRow from "./HeureAgentRow";
import { Reducers } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const showMessageWarning = (param: string) => {
    toast.warning(param, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const HeureAgent = () => {
    const urlApi = process.env.REACT_APP_BACKEND_URL as string;

    const logger = useSelector<Reducers>((state) => state.logger);

    const [dataHeureAgent, setDataHeureAgent] = useState<DataSaisieManager[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [keyword, setKeyword] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalData, setTotalData] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);

    const data = {
        filter: CheckAttr(filter) ? filter : 'empty',
        keyWord: CheckAttr(keyword) ? keyword : 'empty',
        rowPerPage: rowsPerPage,
        page: page,
        token: (logger as any)?.token
    };

    const receiveData = (): void => {
        // Activer un loader dans notre page
        setIsLoading(true);
        axios.post(`${urlApi}parametrage/saisie/manager/list`, data)
            .then(res => {
                const { totalCount, datas } = res.data;

                if (totalCount > 0) {
                    totalCount && setTotalData(totalCount);
                    Array.isArray(datas) && setDataHeureAgent(datas);
                } else {
                    setTotalData(0);
                }
            }).catch((error) => {
                console.log(error.message);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        // Recupère le donnée
        receiveData();
    }, [refresh, page, rowsPerPage]);

    const handleChange = (e): void => {
        setKeyword(e.target.value);

        if (e.target.value.length === 0) {
            setPage(1);
            setRefresh(!refresh);
        }
    };

    const handleSearch = () => {
        if (CheckAttr(filter) && CheckAttr(keyword)) {
            receiveData();
        } else {
            showMessageWarning('SVP! Merci de remplir les Champs de recherche.');
        }
    };

    const pagination: JSX.Element | boolean = (!isLoading && (totalData > rowsPerPage)) && (
        <Stack spacing={2} direction="row">
            <InputLabel sx={{ marginTop: '8px' }}>Lignes par page :</InputLabel>
            <FormControl variant="standard" sx={{ minWidth: 40 }}>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    color="warning"
                    value={rowsPerPage}
                    onChange={(e) => setRowsPerPage(e.target.value as number)}
                    defaultValue={5}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
            </FormControl>
            <Pagination
                count={Math.ceil(totalData / rowsPerPage)}
                color="standard"
                showFirstButton={true}
                showLastButton={true}
                hideNextButton={true}
                hidePrevButton={true}
                siblingCount={0}
                boundaryCount={1}
                defaultPage={page}
                onChange={(event, value) => setPage(value)} />
        </Stack>
    );

    return (
        <Container maxWidth="xl">
            <Box mt={4} mb={2}>
                <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant="h5">
                                <GradingIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Production
                            </Typography>
                            <Typography variant="h6">
                                <AutoStoriesIcon sx={{ position: 'absolute', top: '3px' }} fontSize="medium" />
                                Heures des agents
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                            <FormControl sx={{ minWidth: 220 }}>
                                <InputLabel id="demo-simple-select-label" color="warning" sx={{ mt: -1 }}>Chercher par ...</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter}
                                    size="small"
                                    color="warning"
                                    label="Age"
                                    onChange={(e) => setFilter(e.target.value as string)}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="pays">Pays</MenuItem>
                                    <MenuItem value="bu">Bu</MenuItem>
                                    <MenuItem value="client">Client</MenuItem>
                                    <MenuItem value="operation">Operation</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                sx={{ width: "220px" }}
                                name="keyWord"
                                value={keyword}
                                onChange={handleChange}
                                placeholder="Taper quelque chose ..."
                                size="small"
                                color="warning"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton aria-label="search" edge="end" onClick={handleSearch}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{ my: 4 }} />
            <Box justifyContent="right" display="flex" mt={3} mr={6}>
                {pagination}
            </Box>
            <Paper elevation={3}>
                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                    <Table sx={{ maxHeight: 500 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Pays</StyledTableCell>
                                <StyledTableCell align="center">Bu</StyledTableCell>
                                <StyledTableCell align="center">Client</StyledTableCell>
                                <StyledTableCell align="center">Opération</StyledTableCell>
                                <StyledTableCell align="center">Date saisie</StyledTableCell>
                                <StyledTableCell align="center">Heures Prod</StyledTableCell>
                                <StyledTableCell align="center">Heures Formation</StyledTableCell>
                                <StyledTableCell align="center">Heures Form. Fact.</StyledTableCell>
                                <StyledTableCell align="center">Actes</StyledTableCell>
                                <StyledTableCell align="center">Dmt</StyledTableCell>
                                <StyledTableCell align="center">Login</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <StyledTableCell align="center" colSpan={12}>
                                    <LinearProgress color="warning" />
                                    <Box my={2}><LinearProgress color="warning" /></Box>
                                </StyledTableCell>
                            ) : (
                                dataHeureAgent.length > 0 ? (
                                    dataHeureAgent.map((row) => (
                                        <StyledTableRow key={row.id}>
                                            <HeureAgentRow row={row} refresh={refresh} setRefresh={setRefresh} />
                                        </StyledTableRow>))
                                ) : (
                                    <StyledTableCell colSpan={12} align="center">
                                        Aucun resultat a été trouvé ...
                                    </StyledTableCell>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            <Box justifyContent="left" display="flex" mb={5} mt={2} ml={6}>
                {pagination}
            </Box>
        </Container>
    );
}

export default HeureAgent;
