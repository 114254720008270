import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState, useEffect } from "react";
import { Reducers } from "../../../../redux/reducers";
import { useSelector } from "react-redux";
import axios from "axios";
import ArchiveIcon from "@mui/icons-material/Archive";
import Button from "@mui/material/Button";
import { Player } from "@lottiefiles/react-lottie-player";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
export interface DataClient {
  id: number;
  pays: string;
  bu: string;
  client: string;
  token: string;
}
export interface Parametre {
  client: string;
  mois: string;
  annee: string;
  token: string;
}
interface clientByOP {
  client: string;
}

function Facture() {
  const urlApi = process.env.REACT_APP_BACKEND_URL as string;
  const urlApiC = process.env.REACT_APP_BACKEND_C_SHARPS_URL as string;
  const logger = useSelector<Reducers>((state) => state.logger);
  const d = new Date();
  // const d= new Date(together)
  const [mois, setMois] = React.useState("Janvier 2023");
  const [raisonSocial, SetRaisonSocial] = React.useState("tous");
  const [rows, setRows] = useState<clientByOP[]>([]);
  const [clientE, setClientE] = useState([]);
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const dateDebutFDate = new Date(dateDebut);
  const urlExcel = "http://197.158.82.222:8081/suivirenta/excel/";
  // const filename = "Facture de " + raisonSocial + " le " + mois + ".xlsx";
  const dateDebutArray = dateDebut.split('-');
  const dateFinArray = dateFin.split('-');
  const filename = "Facture de " + raisonSocial + " du " + dateDebutArray[2] +"-"+ dateDebutArray[1] + "-" + dateDebutArray[0] +" au " + dateFinArray[2] +"-"+ dateFinArray[1] + "-" + dateFinArray[0] +".xlsx";
  const [isLoading2, setIsLoading2] = useState(false);
  const urExcelPath = urlExcel + filename;
  console.log(filename);
  const handleChangeDateDebut = (event) => {
    setDateDebut(event.target.value);
  };
  const handleChangeDateFin = (event) => {
    setDateFin(event.target.value);
  };
  useEffect(() => {
    const data = { token: (logger as any)?.token };
    setIsLoading2(true);
    if (rows.length === 0)
      axios
        .post(`${urlApi}getAll/clientbyop`, data)
        .then((res) => {
          setRows(res.data);
          setIsLoading2(false);
        })
        .catch((error) => {
          console.log(error.message);
          setIsLoading2(false);
        });
  }, []);
  const handleChangeRS = (event: SelectChangeEvent) => {
    SetRaisonSocial(event.target.value as string);
  };
  const okok = () => {
    //const data = rows[0].token;  console.log(e.message);

    const data = {
      token: (logger as any)?.token,
      dateDeb: dateDebut,
      dateFn: dateFin,
    };
    if (raisonSocial !== "tous") {
      axios
        .post(`${urlApi}facture/parametre/${raisonSocial}`, data)
        .then((res) => {
          setIsLoading2(false);
          window.open(urExcelPath, "_blank");
        })
        .catch((error) => {
          console.log(error.message);
          setIsLoading2(false);
        });
    } else {
      console.log("tous");
      // Maka Ny Client Rehetra
      axios
        .post(
          //Mila Amboarina
          `${urlApiC}/api/saisie/manager/get/allclient/date`,
          data
        )
        .then((res) => {
          setClientE(res.data);
          if (res.data.count !== 0) {
            setIsLoading2(true);
            //window.location.href= urlExcel+"Facture de "+x+" le "+mois+".xlsx"
            axios
              .post(`${urlApi}facture/parametre/${raisonSocial}`, data)
              .then((resTsyIzy) => {
                res.data.map((x) =>
                  window.open(
                    urlExcel + "Facture de " + x + " du " + dateDebutArray[2] +"-"+ dateDebutArray[1] + "-" + dateDebutArray[0] +" au " + dateFinArray[2] +"-"+ dateFinArray[1] + "-" + dateFinArray[0] +".xlsx",
                    "_blank"
                  )
                );
              })
              .catch((error) => {
                console.log(error.message);
              })
              .finally(function () {
                setIsLoading2(false);
              });
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    /*
        axios.post(`${urlApi}facture/parametre/${raisonSocial}/${mois}`, data)
        .then((res) => {
            setIsLoading2(false);
            if(raisonSocial !== 'tous'){
                    window.open(urExcelPath,'_blank');
            }
            if (raisonSocial === 'tous'){
                const moisAnnee= mois.split(" ");
                axios.get(`${urlApiC}/api/saisie/manager/get/allclient/${volana[moisAnnee[0]]}/${moisAnnee[1]}`)
                .then((res) => {
                    setClientE(res.data);
                        res.data.map((x) =>(
                            //window.location.href= urlExcel+"Facture de "+x+" le "+mois+".xlsx" 
                            window.open(urlExcel+"Facture de "+x+" le "+mois+".xlsx", '_blank'))
                        )   
                   
                })
                .catch((error) => {
                    console.log(error.message);
                });   
            } 
           
        }).catch((e) => {
        setIsLoading2(true);
          console.log(e.message);
        }) */
    var request = new XMLHttpRequest();
    request.open(
      "GET",
      "http://197.158.82.222:8081/suivirenta/excel/Facture%20de%20BEKdsdO%20le%20septembre%202022.xlsx",
      true
    );
    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 404) {
          alert("Oh no, it does not exist!");
        }
      }
    };
  };
  console.log(dateDebut);
  console.log(dateFin);
  return (
    <div className="Facture">
      {isLoading2 ? (
        <Box my={2}>
          <LinearProgress color="warning" />
        </Box>
      ) : (
        <br></br>
      )}
      <Player
        autoplay
        loop
        src="../../../../facture.json"
        style={{
          height: "600px",
          width: "600px",
          position: "fixed",
          top: "290px",
        }}
      ></Player>

      <Player
        autoplay
        loop
        src="../../../../bg.json"
        style={{
          height: "300px",
          width: "300px",
          position: "fixed",
          top: "690px",
          left: "1000px",
        }}
      >
        <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>{" "}
        <br></br>
        <Grid container spacing={3} position="relative">
          <Grid xs={7}></Grid>
          <Grid xs={4}>
            <br></br>
            <Container maxWidth="sm">
              <Stack spacing={2}>
                <Grid container spacing={3} position="relative">
                  <Grid xs={7}>
                    <InputLabel
                      id="demo-simple-select-label-mois"
                      color="warning"
                    >
                      Date Debut
                    </InputLabel>
                    <FormControl
                      style={{
                        width: "200px",
                      }}
                    >
                      <input
                        name="dateCreate"
                        type="date"
                        defaultValue=""
                        color="warning"
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #AAAAAA",
                          height: "38px",
                          color: "#757575",
                          fontSize: "14px",
                        }}
                        value={dateDebut}
                        onChange={handleChangeDateDebut}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={4}>
                    <InputLabel
                      id="demo-simple-select-label-mois"
                      color="warning"
                    >
                      Date Fin
                    </InputLabel>
                    <FormControl
                      style={{
                        width: "200px",
                      }}
                    >
                      <input
                        name="dateCreate"
                        type="date"
                        defaultValue=""
                        color="warning"
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #AAAAAA",
                          height: "38px",
                          color: "#757575",
                          fontSize: "14px",
                        }}
                        value={dateFin}
                        onChange={handleChangeDateFin}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl
                  style={{
                    width: "400px",
                  }}
                >
                  <InputLabel id="rs-label" color="warning">
                    Raison Social
                  </InputLabel>
                  <Select
                    name="client"
                    labelId="rs-label"
                    id="select"
                    value={raisonSocial}
                    label="Raison Social"
                    onChange={handleChangeRS}
                    color="warning"
                  >
                    <MenuItem value="tous">tous</MenuItem>
                    {rows.map((row, index) => {
                      return (
                        <MenuItem key={"cell_" + index} value={row["client"]}>
                          {row["client"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    width: "400px",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    endIcon={<ArchiveIcon />}
                    color="warning"
                    onClick={okok}
                  >
                    Generer Facture
                  </Button>
                </FormControl>
                <br></br>
              </Stack>
            </Container>
          </Grid>
        </Grid>
      </Player>
    </div>
  );
}

export default Facture;
