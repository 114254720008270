import { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import client from './components/content/parametrage/client/Client';
import bu from './components/content/parametrage/bu/Bu';
import pays from './components/content/parametrage/pays/Pays';
import operation from './components/content/parametrage/operation/Operation';
import PaysFacturation from './components/content/parametrage/paysFacturation/PaysFacturation';
import SocieteFacturation from './components/content/parametrage/societeFacturation/SocieteFacturation';
import SaisieActe from './components/content/saisie_acte/SaisieActe';
import ListeTarif from './components/content/tarifAndCout/tarif/ListeTarif';
import ListeCout from './components/content/tarifAndCout/cout/ListeCout';
import SaisieManager from './components/content/Production/HeureAgent';
import EntryPoint from './components/content/EntryPoint';
import PrivateRoute from './commons/private-route';
import Facture from './components/content/parametrage/facture/Facture';
import AllFacture from './components/content/parametrage/facture/AllFacture';
import Budget from './components/content/Budget/Budget';
import FamilleOperation from './components/content/parametrage/familleOperation/FamilleOperation';
function router() {
    return (
        <Suspense fallback={<div>Chargement ...</div>}>
            <Switch>
                <PrivateRoute path="/entry/point/:token" component={EntryPoint} exact />
                <PrivateRoute path="/parametrage/pays" component={pays} exact />
                <PrivateRoute path="/parametrage/bu" component={bu} exact />
                <PrivateRoute path="/parametrage/client" component={client} exact />
                <PrivateRoute path="/parametrage/operation" component={operation} exact />
                <PrivateRoute path="/parametrage/famille/operation" component={FamilleOperation} exact />
                <PrivateRoute path="/parametrage/pays-facturation" component={PaysFacturation} exact />
                <PrivateRoute path="/parametrage/societe-facturation" component={SocieteFacturation} exact />
                <PrivateRoute path="/saisie/acte" component={SaisieActe} exact />
                <PrivateRoute path="/list/tarif" component={ListeTarif} exact />
                <PrivateRoute path="/list/cout" component={ListeCout} exact />
                <PrivateRoute path="/production/saisie/manager" component={SaisieManager} exact />
                <PrivateRoute path="/facture" component={Facture} exact />
                <PrivateRoute path="/allfacture" component={AllFacture} exact />
                <PrivateRoute path="/budget/client" component={Budget} exact />
            </Switch>
        </Suspense>
    )
}

export default router;
