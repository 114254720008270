import axios from 'axios';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../../redux/reducers';
import Facture from './Facture';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}
function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} open={open}>

            <Alert severity="warning">ATTENTION</Alert>
            <DialogTitle>veuillez choisir au moins une facture s'il vous plaît</DialogTitle>
        </Dialog>
    );
}
interface FactureChecked {
    NameOperation: string;
    Status: number;
    Durre: string;
    ListeFactureTotal: Facture[];
}
interface Facture {
    operation: string;
    typeOperation: string;
    typeDoc: string;
    NombrePiece: string;
    Date: string;
    DateFarany: string;
    Tiers: string;
    Reference: string;
    Designation: string;
    PrixUnitaire: string;
    Quantite: string;
    codetaxe: string;
    token: string;
}
interface DataClient {

    id: number;
    pays: string;
    bu: string;
    client: string;
    token: string;
}


function AllFacture() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');

    const handleClose = (value: string) => {
        setOpen(false);
    };

    const urlApi = process.env.REACT_APP_BACKEND_URL as string;
    const d = new Date();
    const year = d.getFullYear();
    const months = ['Janvier', 'Fevrier', 'Mars', 'avril', 'Mai', 'Juin', 'juillet', 'Aout', 'septembre', 'Octobre', 'November', 'Decembre', 'Janvier', 'Fevrier', 'Mars', 'avril', 'Mai', 'Juin', 'juillet', 'Aout', 'septembre', 'Octobre', 'November', 'Decembre'];

    const [mois, setMois] = React.useState(months[d.getMonth() - 1 + 12] + " " + String((1 - d.getMonth() + 12 < 13) ? year : year - 1));
    const [raisonSocial, SetRaisonSocial] = React.useState('tous');
    const [clientChecked, setClientChecked] = React.useState<string[]>([]);
    const [dateFarany, setDateFarany] = React.useState<string>('Decembre 2021');
    const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    let resultat = number.map((n) => months[d.getMonth() - n + 12] + " " + String((n - d.getMonth() + 12 < 13) ? year : year - 1))

    const [clientRows, setClientRows] = useState<FactureChecked[]>([]);
    const [rows, setRows] = useState<DataClient[]>([]);
    const logger = useSelector<Reducers>((state) => state.logger);
    const urltous = urlApi + "facture/getAll/tous/" + dateFarany;
    useEffect(() => {
        const data = { token: (logger as any)?.token };
        if (rows.length === 0)
            axios.post(`${urlApi}getAll/clientbyop`, data)
                .then((res) => {
                    setRows(res.data);
                })
                .catch((error) => {
                    console.log(error.message);
                });

        axios.post(`${urltous}`, data)
            .then((res) => {
                setClientRows(res.data);
            })
            .catch((error) => {
                console.log(error.message);
            });



    }, []);
    const handleClientCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = clientChecked.indexOf(event.target.value)
        if (index === -1) {
            setClientChecked([...clientChecked, event.target.value])
        } else {
            setClientChecked(clientChecked.filter((x) => x !== event.target.value))
        }
    }

    const handleChangeRS = (event: SelectChangeEvent) => {
        SetRaisonSocial(event.target.value as string);

    };
    const handleChange = (event: SelectChangeEvent) => {
        setMois(event.target.value as string);
    };
    const okok = () => {
        const data = rows[0].token;
        console.log(clientChecked);

        axios.post(`${urlApi}facture/getAll/${raisonSocial}/${mois}`, data).then((res) => {
            setClientRows(res.data);

        }).then(console.log).catch(console.log);
    };
    const valider = () => {
        if (clientChecked.length === 0) {
            setOpen(true);
        }
        else {
            axios.post(`${urlApi}set/clientcheckedphp`, clientChecked)
                .catch((error) => {
                    console.log(error.message);
                });
        }

    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#777',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <div>
            <br></br>
            <Container >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, marginTop: 1 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormControl
                                        style={{
                                            width: "340px"
                                        }}
                                    >
                                        <InputLabel id="demo-simple-select-label-mois" color="warning">Periode</InputLabel>
                                        <Select
                                            name="mois"
                                            labelId="demo-simple-select-label-mois"
                                            id="demo-simple-select-mois"
                                            value={mois}
                                            label="mois"
                                            size='small'
                                            onChange={handleChange}
                                            color="warning"
                                        >
                                            {
                                                resultat.map((x) =>
                                                    <MenuItem value={x}>
                                                        {x}
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <FormControl
                                        style={{
                                            width: "350px"
                                        }}
                                    >
                                        <InputLabel id="rs-label" color="warning">Raison Social</InputLabel>
                                        <Select
                                            name="client"
                                            labelId="rs-label"
                                            id="select"
                                            value={raisonSocial}
                                            label="Raison Social"
                                            size='small'
                                            onChange={handleChangeRS}
                                            color="warning"
                                        >
                                            <MenuItem value="tous">tous</MenuItem>
                                            {rows.map((row, index) => {
                                                return (
                                                    <MenuItem key={"cell_" + index} value={row['client']}>{row['client']}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell >
                                    <Button variant="contained" type="submit" endIcon={<FindInPageIcon />} color="warning" onClick={okok}>
                                        Recherche
                                    </Button>
                                </TableCell>
                                <TableCell align="right">
                                    <Button variant="outlined" type="submit" endIcon={<DoneOutlineIcon />} color="warning" onClick={valider}>
                                        Valider
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </Container>
            <br />
            <Container>
                {clientRows.map((cl, index) => {
                    return (
                        <div>

                            {(cl.ListeFactureTotal.length === 0) ?
                                <div></div>
                                :
                                <div>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="sticky table">
                                            <TableHead>
                                                <TableRow key={index}>
                                                    <StyledTableCell>
                                                        {cl.Status === 0 ?
                                                            <Checkbox {...label}
                                                                {...label}
                                                                value={cl.NameOperation + "123456789" + cl.Durre}
                                                                checked={clientChecked.includes(cl.NameOperation + "123456789" + cl.Durre)}

                                                                onChange={handleClientCheckedChange}
                                                                sx={{
                                                                    color: 'white',
                                                                    '&.Mui-checked': {
                                                                        color: 'white',
                                                                    },
                                                                }} />
                                                            :
                                                            <Checkbox {...label}
                                                                {...label}
                                                                value={cl.NameOperation + "123456789" + cl.Durre}
                                                                checked={clientChecked.includes(cl.NameOperation + "123456789" + cl.Durre)}

                                                                sx={{
                                                                    color: 'white',
                                                                    '&.Mui-checked': {
                                                                        color: 'white',
                                                                    },
                                                                }} disabled />}
                                                    </StyledTableCell>
                                                    <StyledTableCell>Type Doc</StyledTableCell>
                                                    <StyledTableCell align="right">N° Pieces</StyledTableCell>
                                                    <StyledTableCell align="right">Date</StyledTableCell>
                                                    <StyledTableCell align="right">Tiers</StyledTableCell>
                                                    <StyledTableCell align="right">Reference Articles</StyledTableCell>
                                                    <StyledTableCell align="right">Designation</StyledTableCell>
                                                    <StyledTableCell align="right">Prix Unitaire</StyledTableCell>
                                                    <StyledTableCell align="right">Quantite</StyledTableCell>
                                                    <StyledTableCell align="right">Code taxes</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cl.ListeFactureTotal.map((operation, indexop) => {
                                                    return (
                                                        <StyledTableRow key={indexop}>
                                                            <StyledTableCell component="th" scope="row"></StyledTableCell>
                                                            <StyledTableCell component="th" scope="row"></StyledTableCell>
                                                            <StyledTableCell align="right"></StyledTableCell>
                                                            <StyledTableCell align="right">{operation.DateFarany}</StyledTableCell>
                                                            <StyledTableCell align="right"></StyledTableCell>
                                                            <StyledTableCell align="right">{operation.Reference}</StyledTableCell>
                                                            <StyledTableCell align="right">{operation.Designation}</StyledTableCell>
                                                            <StyledTableCell align="right">{operation.PrixUnitaire}</StyledTableCell>
                                                            <StyledTableCell align="right">{operation.Quantite}</StyledTableCell>
                                                            <StyledTableCell align="right">{operation.codetaxe}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>}
                            <br></br>

                        </div>
                    )
                })}
            </Container>

            <Container>
                <Button variant="outlined" type="submit" endIcon={<DoneOutlineIcon />} color="warning" onClick={valider}>
                    Valider
                </Button>
            </Container>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
            <br></br>

        </div>
    );
}
export default AllFacture;