import { styled, alpha } from "@mui/material/styles";
import React, { useState } from "react";
import Menu, { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useHistory } from "react-router";
import { ListItemText, ListItemIcon, ListItem, List, Divider, MenuItem, Typography, Box } from "@mui/material";

const saisie = process.env.REACT_APP_SAISIE_URL as string;
const production = process.env.REACT_APP_PRODUCTION_URL as string;
const supervision = process.env.REACT_APP_SUPERVISION_URL as string;
const flash = process.env.REACT_APP_FLASH_URL as string;
const tableau = process.env.REACT_APP_TABLEAU_URL as string;
const flashTab = process.env.REACT_APP_TABLEAU_FLASH_URL as string;
const historiquePrev = process.env.REACT_APP_HISTORIQUE_PREV_URL as string;
const extractionPrev = process.env.REACT_APP_EXTRACTION_PREV_FLASH_URL as string;

const joursOuvres = process.env.REACT_APP_JOURS_OUVRES_URL as string;
const utilisateur = process.env.REACT_APP_UTILISATEURS_URL as string;
const saisieManager = process.env.REACT_APP_SAISIE_MANAGER_URL as string;
const saisieOperation = process.env.REACT_APP_SAISIE_OPERATIONS_URL as string;

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: 13,
  fontFamily: ['Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginLeft: theme.spacing(-2.5),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const SideMenuResponsive = () => {
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const openMenu1 = Boolean(anchorEl1);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const openMenu2 = Boolean(anchorEl2);
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const openMenu3 = Boolean(anchorEl3);
  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null);
  const openMenu4 = Boolean(anchorEl4);
  const [anchorEl5, setAnchorEl5] = useState<null | HTMLElement>(null);
  const openMenu5 = Boolean(anchorEl5);
  const [anchorEl6, setAnchorEl6] = useState<null | HTMLElement>(null);
  const openMenu6 = Boolean(anchorEl6);
  const [anchorEl7, setAnchorEl7] = useState<null | HTMLElement>(null);
  const openMenu7 = Boolean(anchorEl7);

  const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const handleClick4 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const handleClick5 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  const handleClick6 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl6(event.currentTarget);
  };
  const handleClose6 = () => {
    setAnchorEl6(null);
  };

  const handleClick7 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl7(event.currentTarget);
  };
  const handleClose7 = () => {
    setAnchorEl7(null);
  };

  const history = useHistory();

  const handleClickParam = (url) => {
    setAnchorEl1(null);
    history.push(url);
  };

  return (
    <Box>
      <Typography
        variant="h5"
        color="#e75113"
        style={{ marginLeft: "8px", padding: "10px", textShadow: '#828181 0.06em 0.06em 0.06em' }}
      >
        Suivi Rentabilité
      </Typography>
      <List>
        <ListItem
          button
          sx={{
            mb: 0,
            color: "#777",
            display: "block",
            textTransform: "none",
            fontSize: "17px",
            backgroundColor: '#e7e7e7'
          }}>
          <ListItemText primary="Deconnexion" />
        </ListItem>

        <ListItem
          button
          aria-controls="parametrage-menu"
          aria-haspopup="true"
          aria-expanded={openMenu1 ? "true" : undefined}
          onClick={handleClick1}
          sx={{
            mb: 0,
            color: "#777",
            textTransform: "none",
            fontSize: "15px",
          }}>
          <ListItemText primary="Paramètrage" />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
        <StyledMenu
          id="parametrage-menu"
          MenuListProps={{
            "aria-labelledby": "parametrage-menu-button",
          }}
          anchorEl={anchorEl1}
          open={openMenu1}
          onClose={handleClose1}
        >
          <StyledMenuItem onClick={() => handleClickParam('/parametrage/pays')} disableRipple>
            Pays
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleClickParam('/parametrage/bu')} disableRipple>
            BUs
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleClickParam('/parametrage/client')} disableRipple>
            Clients
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleClickParam('/parametrage/operation')} disableRipple>
            Opérations
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleClickParam('/parametrage/famille/operation')} disableRipple>
            Famille opération
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleClickParam('/parametrage/pays-facturation')} disableRipple>
            Pays de facturation
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleClickParam('/parametrage/societe-facturation')} disableRipple>
            Société de facturation
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(joursOuvres)} disableRipple>
            Jours Ouvrés
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(utilisateur)} disableRipple>
            Utilisateurs
          </StyledMenuItem>
          <Divider sx={{ my: 0.5 }} />
          <StyledMenuItem disableRipple disabled>
            Administrations
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(saisieManager)} disableRipple>
            Heures des Agents
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(saisieOperation)} disableRipple>
            Suivi saisie des opérations
          </StyledMenuItem>
        </StyledMenu>
        <Divider />

        <ListItem
          button
          aria-controls="tarif-menu"
          aria-haspopup="true"
          aria-expanded={openMenu2 ? "true" : undefined}
          onClick={handleClick2}
          sx={{
            mb: 0,
            color: "#777",
            textTransform: "none",
            fontSize: "15px",
          }}>
          <ListItemText primary="Tarifs & Coûts" />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
        <StyledMenu
          id="tarif-menu"
          MenuListProps={{
            "aria-labelledby": "tarif-menu-button",
          }}
          anchorEl={anchorEl2}
          open={openMenu2}
          onClose={handleClose2}
        >
          <StyledMenuItem onClick={handleClose2} disableRipple>
            Tarifs
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose2} disableRipple>
            Coûts
          </StyledMenuItem>
        </StyledMenu>
        <Divider />

        <ListItem
          button
          aria-controls="manager-menu"
          aria-haspopup="true"
          aria-expanded={openMenu3 ? "true" : undefined}
          onClick={handleClick3}
          sx={{
            mb: 0,
            color: "#777",
            textTransform: "none",
            fontSize: "15px",
          }}>
          <ListItemText primary="Managers" />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
        <StyledMenu
          id="manager-menu"
          MenuListProps={{
            "aria-labelledby": "manager-menu-button",
          }}
          anchorEl={anchorEl3}
          open={openMenu3}
          onClose={handleClose3}
        >
          <StyledMenuItem onClick={handleClose3} disableRipple>
            Managers
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose3} disableRipple>
            Managers par opérations
          </StyledMenuItem>
        </StyledMenu>
        <Divider />

        <ListItem
          button
          aria-controls="production-menu"
          aria-haspopup="true"
          aria-expanded={openMenu4 ? "true" : undefined}
          onClick={handleClick4}
          sx={{
            mb: 0,
            color: "#777",
            textTransform: "none",
            fontSize: "15px",
          }}>
          <ListItemText primary="Production" />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
        <StyledMenu
          id="production-menu"
          MenuListProps={{
            "aria-labelledby": "production-menu-button",
          }}
          anchorEl={anchorEl4}
          open={openMenu4}
          onClose={handleClose4}
        >
          <StyledMenuItem onClick={handleClose4} disableRipple>
            Heures des Agents
          </StyledMenuItem>
          <Divider sx={{ my: 0.5 }} />
          <StyledMenuItem disableRipple disabled>
            Gestion
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose4} disableRipple>
            Heures des Agents
          </StyledMenuItem>
          <StyledMenuItem disableRipple disabled>
            Administration
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose4} disableRipple>
            Heures des Agents
          </StyledMenuItem>
        </StyledMenu>
        <Divider />

        <ListItem
          button
          aria-controls="previsionnel-menu"
          aria-haspopup="true"
          aria-expanded={openMenu5 ? "true" : undefined}
          onClick={handleClick5}
          sx={{
            mb: 0,
            color: "#777",
            textTransform: "none",
            fontSize: "15px",
          }}>
          <ListItemText primary="Prévisionnel" />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
        <StyledMenu
          id="previsionnel-menu"
          MenuListProps={{
            "aria-labelledby": "previsionnel-menu-button",
          }}
          anchorEl={anchorEl5}
          open={openMenu5}
          onClose={handleClose5}
        >
          <StyledMenuItem onClick={handleClose5} disableRipple>
            Prévisionnel par opération
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose5} disableRipple>
            Historique
          </StyledMenuItem>
          <Divider sx={{ my: 0.5 }} />
          <StyledMenuItem disableRipple disabled>
            Administration
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose5} disableRipple>
            Historique
          </StyledMenuItem>
        </StyledMenu>
        <Divider />

        <ListItem
          button
          aria-controls="budget-menu"
          aria-haspopup="true"
          aria-expanded={openMenu6 ? "true" : undefined}
          onClick={handleClick6}
          sx={{
            mb: 0,
            color: "#777",
            textTransform: "none",
            fontSize: "15px",
          }}>
          <ListItemText primary="Budget" />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
        <StyledMenu
          id="budget-menu"
          MenuListProps={{
            "aria-labelledby": "budget-menu-button",
          }}
          anchorEl={anchorEl6}
          open={openMenu6}
          onClose={handleClose6}
        >
          <StyledMenuItem onClick={handleClose6} disableRipple>
            Budget par client
          </StyledMenuItem>
        </StyledMenu>
        <Divider />

        <ListItem
          button
          aria-controls="extraction-menu"
          aria-haspopup="true"
          aria-expanded={openMenu7 ? "true" : undefined}
          onClick={handleClick7}
          sx={{
            mb: 0,
            color: "#777",
            textTransform: "none",
            fontSize: "15px",
          }}>
          <ListItemText primary="Extraction" />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItem>
        <StyledMenu
          id="extraction-menu"
          MenuListProps={{
            "aria-labelledby": "extraction-menu-button",
          }}
          anchorEl={anchorEl7}
          open={openMenu7}
          onClose={handleClose7}
        >
          <StyledMenuItem onClick={() => window.location.assign(saisie)} disableRipple>
            Saisie
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(production)} disableRipple>
            Renta clients
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(supervision)} disableRipple>
            Coûts de Supervision
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(flash)} disableRipple>
            Flash
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(tableau)} disableRipple>
            Tableau SI
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(flashTab)} disableRipple>
            Tableau SI FLASH
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(historiquePrev)} disableRipple>
            Historique Prévisionnel
          </StyledMenuItem>
          <StyledMenuItem onClick={() => window.location.assign(extractionPrev)} disableRipple>
            Extraction Prévisionnel
          </StyledMenuItem>
        </StyledMenu>
        <Divider />

      </List>
    </Box>
  );
};

export default SideMenuResponsive;
