import {
  useState,
  useEffect,
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
} from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  FormControl,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { Button, CircularProgress, TextField, Select } from "@mui/material";
import CheckAttr from "../../function/CheckAttr";
import { Alert } from "@material-ui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataPays from "../../interface/DataPays";
import DataBu from "../../interface/DataBu";
import DataClient from "../../interface/DataClient";
import DataFamilleOperation from "../../interface/DataFamilleOperation";
import DataOperation from "../../interface/DataOperation";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataSocieteFacturation from "../../interface/DataSocieteFacturation";
import SocieteFact from "../../interface/SocieteFact";

toast.configure();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    TextField: {
      width: "100%",
    },
    warning: {
      color: theme.palette.primary.light,
    },
    loading: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    messageWarning: {
      color: "red",
      display: "flex",
      alignItems: "center",
    },
  })
);

const showMessageToastError = (): void => {
  toast.error("Une erreure s'est produite, merci de ressayer.", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

const showMessageToastSuccess = (msg: string): void => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

const showMessageWarning = (param: string) => {
  toast.warning(param, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

interface DataIRMClient {
  Client_ID: string;
  Client_Libelle: string;
}

interface DataIRMOperation {
  Operation_ID: string;
  Operation_Libelle: string;
}

interface OperationValidation {
  pays: boolean;
  bu: boolean;
  client: boolean;
  operation: boolean;
}

interface OperationRowFormProps {
  operation?: DataOperation;
  allPays: DataPays[];
  allBus: DataBu[];
  allClients: DataClient[];
  allFamilleOperations: DataFamilleOperation[];
  allSociete: SocieteFact[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  refresh: boolean;
}

const initialValidationInput: OperationValidation = {
  pays: false,
  bu: false,
  client: false,
  operation: false,
};

const OperationForm: FC<OperationRowFormProps> = ({
  operation,
  allPays,
  allBus,
  allClients,
  allFamilleOperations,
  setOpenModal,
  setRefresh,
  refresh,
  allSociete,
}) => {
  const classes = useStyles();

  const urlApi = process.env.REACT_APP_BACKEND_URL as string;

  let operationDup: DataOperation | undefined;
  if (operation) {
    const buDup = allBus?.find(
      (elem) =>
        elem?.bu1?.toLocaleLowerCase()?.replace(/\s+/g, "") ===
        operation?.bu?.toLocaleLowerCase()?.replace(/\s+/g, "")
    )?.bu1 as string;
    const clientDup = allClients?.find(
      (elem) =>
        elem?.client1?.toLocaleLowerCase()?.replace(/\s+/g, "") ===
        operation?.client?.toLocaleLowerCase()?.replace(/\s+/g, "")
    )?.client1 as string;

    operationDup = {
      ...operation,
      bu: buDup,
      client: clientDup,
    };
  }

  const [isClickSave, setIsClickSave] = useState<boolean>(false);
  const [isReceiveClientIRM, setIsReceiveClientIRM] = useState<boolean>(false);
  const [idSocieteFact, setIdSocieteFact] = useState<SocieteFact>();
  const [isReceiveOperationIRM, setIsReceiveOperationIRM] =
    useState<boolean>(false);
  const [irmClient, setIrmClient] = useState<DataIRMClient[]>([]);
  const [irmOperation, setIrmOperation] = useState<DataIRMOperation[]>([]);
  const [operationToSave, setOperationToSave] = useState<
    DataOperation | undefined
  >(operationDup);
  const [errorInput, setErrorInput] = useState<OperationValidation>(
    initialValidationInput
  );

  useEffect(() => {
    if (CheckAttr(operationToSave?.pays)) {
      setIsReceiveClientIRM(true);
      axios
        .get(`${urlApi}get/irm/data/${operationToSave?.pays}`)
        .then((res) => {
          Array.isArray(res.data) && setIrmClient(res.data);
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => {
          setIsReceiveClientIRM(false);
        });
    }
  }, [operationToSave?.pays]);

  useEffect(() => {
    if (CheckAttr(operationToSave?.idClientIrm)) {
      setIsReceiveOperationIRM(true);
      axios
        .get(
          `${urlApi}get/irm/data/${operationToSave?.pays}/${operationToSave?.idClientIrm}`
        )
        .then((res) => {
          Array.isArray(res.data) && setIrmOperation(res.data);
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => {
          setIsReceiveOperationIRM(false);
        });
    }
  }, [operationToSave?.idClientIrm]);
  // console.log(operationToSave);

  const handleChangeSociete = (e: any): void => {
    const { name, value } = e.target;
    if (name === "idSocieteFact") {
      console.log("tafiditra");
      setOperationToSave({
        ...(operationToSave as DataOperation),
        idSocieteFacturation: value,
      });
    }
  };
  const handleChange = (e: any): void => {
    const { name, value } = e.target;
    if (name === "pays") {
      if (CheckAttr(value)) {
        setOperationToSave({
          ...(operationToSave as DataOperation),
          pays: value,
        });
      } else {
        setIrmClient([]);
        setIrmOperation([]);

        setOperationToSave({
          ...(operationToSave as DataOperation),
          idClientIrm: undefined,
          idOperationIrm: undefined,
          operationIrm: undefined,
        });
      }
    } else if (name === "client") {
      let tmpClientIrm: number | undefined;

      const tmpIrmCl = allClients?.find(
        (cl) =>
          cl?.id_client_irm !== null &&
          cl?.client1?.toLowerCase()?.replace(/\s+/g, "") ===
            value?.toLowerCase()?.replace(/\s+/g, "")
      )?.id_client_irm;

      if (tmpIrmCl) {
        tmpClientIrm = Number(
          irmClient?.find((cust) => Number(cust?.Client_ID) === tmpIrmCl)
            ?.Client_ID
        );
      }

      setOperationToSave({
        ...(operationToSave as DataOperation),
        client: value,
        idClientIrm: tmpClientIrm,
      });
    } else if (name === "idClientIrm") {
      if (CheckAttr(value)) {
        setOperationToSave({
          ...(operationToSave as DataOperation),
          idClientIrm: value,
        });
      } else {
        setIrmOperation([]);
        setOperationToSave({
          ...(operationToSave as DataOperation),
          idOperationIrm: undefined,
          operationIrm: undefined,
        });
      }
    } else if (name === "idOperationIrm") {
      if (!CheckAttr(value)) {
        setOperationToSave({
          ...(operationToSave as DataOperation),
          operationIrm: undefined,
          idOperationIrm: undefined,
        });
      } else {
        const libelleOperationIrm = irmOperation?.find(
          (opera) => opera?.Operation_ID === value
        )?.Operation_Libelle;

        setOperationToSave({
          ...(operationToSave as DataOperation),
          operationIrm: libelleOperationIrm,
          idOperationIrm: value,
        });
      }
    } else {
      setOperationToSave({
        ...(operationToSave as DataOperation),
        [name]: value,
      });
    }
  };
  console.log(operationToSave);
  const checkValidationInput = (): boolean => {
    if (
      !operationToSave?.pays ||
      !operationToSave?.bu ||
      !operationToSave?.client ||
      !operationToSave?.operation1
    ) {
      if (!CheckAttr(operationToSave?.pays)) {
        setErrorInput((prev: any) => ({ ...prev, pays: true }));
        showMessageWarning("Merci de remplir le champ Pays !");
      }

      if (!CheckAttr(operationToSave?.bu)) {
        setErrorInput((prev: any) => ({ ...prev, bu: true }));
        showMessageWarning("Merci de remplir le champ Bu !");
      }

      if (!CheckAttr(operationToSave?.client)) {
        setErrorInput((prev: any) => ({ ...prev, client: true }));
        showMessageWarning("Merci de remplir le champ pour Client !");
      }

      if (!CheckAttr(operationToSave?.operation1)) {
        setErrorInput((prev: any) => ({ ...prev, operation: true }));
        showMessageWarning("Merci de remplir le champ Opération !");
      }

      return false;
    }

    return true;
  };

  const handleSave = (): void => {
    // reinitialiser à false les valeurs des attributs qui controle les champs obligatoire
    setErrorInput(initialValidationInput);

    // Control de tout les champs obligatoire
    if (checkValidationInput()) {
      setIsClickSave(true);

      if (operationToSave?.id) {
        // update Société de facturation
        axios
          .put(`${urlApi}parametrage/operation/update`, operationToSave)
          .then((res) => {
            showMessageToastSuccess(res.data);
            setOpenModal(false);
            setRefresh(!refresh);
          })
          .catch((e) => {
            alert(e.message);
            showMessageToastError();
          })
          .finally(() => {
            setIsClickSave(false);
          });
      } else {
        //  create new Société de facturation
        axios
          .post(`${urlApi}parametrage/operation/create`, operationToSave)
          .then((res) => {
            showMessageToastSuccess(res.data);
            setOpenModal(false);
            setRefresh(!refresh);
          })
          .catch((e) => {
            alert(e.message);
            showMessageToastError();
          })
          .finally(() => {
            setIsClickSave(false);
          });
      }
    }
  };

  const paysLabel = (
    <Fragment>
      <span>Pays</span>
      <span style={{ color: "red", fontSize: 20 }}> *</span>
    </Fragment>
  );

  const buLabel = (
    <Fragment>
      <span>Bu</span>
      <span style={{ color: "red", fontSize: 20 }}> *</span>
    </Fragment>
  );

  const clientLabel = (
    <Fragment>
      <span>Client</span>
      <span style={{ color: "red", fontSize: 20 }}> *</span>
    </Fragment>
  );

  const operationLabel = (
    <Fragment>
      <span>Opération</span>
      <span style={{ color: "red", fontSize: 20 }}> *</span>
    </Fragment>
  );

  /**
   * Logique show message validarion forms........................
   */
  const messageValidation = (!operationToSave?.pays ||
    !operationToSave?.bu ||
    !operationToSave?.client ||
    !operationToSave?.operation1) && (
    <Alert variant="filled" severity="error">
      Attention tous les champs en <b>*</b> rouge sont obligatoire, merci de
      remplir SVP !
    </Alert>
  );

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="pays"
              shrink={operationToSave?.pays !== undefined && true}
            >
              {paysLabel}
            </InputLabel>
            <Select
              value={operationToSave?.pays}
              onChange={handleChange}
              error={errorInput?.pays}
              color="warning"
              inputProps={{
                name: "pays",
              }}
              native
            >
              <option value=""></option>
              {allPays.length === 0 ? (
                <option disabled>Loading ...</option>
              ) : (
                allPays.map((item) => (
                  <option key={item?.id_pays} value={item?.pays1}>
                    {item?.pays1}
                  </option>
                ))
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="bu"
              shrink={operationToSave?.bu !== undefined && true}
            >
              {buLabel}
            </InputLabel>
            <Select
              value={operationToSave?.bu}
              onChange={handleChange}
              error={errorInput?.bu}
              color="warning"
              inputProps={{
                name: "bu",
              }}
              native
            >
              <option value=""></option>
              {allBus.length === 0 ? (
                <option disabled>Loading ...</option>
              ) : (
                allBus.map((item) => (
                  <option key={item?.id} value={item?.bu1}>
                    {item?.bu1}
                  </option>
                ))
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="client"
              shrink={operationToSave?.client !== undefined && true}
            >
              {clientLabel}
            </InputLabel>
            <Select
              value={operationToSave?.client}
              onChange={handleChange}
              error={errorInput?.client}
              color="warning"
              inputProps={{
                name: "client",
              }}
              native
            >
              <option value=""></option>
              {allClients.length === 0 ? (
                <option disabled>Loading ...</option>
              ) : (
                allClients.map((item) => (
                  <option key={item?.id} value={item?.client1}>
                    {item?.client1}
                  </option>
                ))
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="idClientIrm"
              shrink={CheckAttr(operationToSave?.idClientIrm)}
            >
              Client IRM
            </InputLabel>
            <Select
              value={operationToSave?.idClientIrm}
              onChange={handleChange}
              color="warning"
              inputProps={{
                name: "idClientIrm",
              }}
              native
            >
              <option value=""></option>
              {isReceiveClientIRM ? (
                <option disabled>Loading ...</option>
              ) : (
                irmClient.map((item) => (
                  <option key={item?.Client_ID} value={item?.Client_ID}>
                    {item?.Client_Libelle}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label={operationLabel}
              name="operation1"
              color="warning"
              error={errorInput?.operation}
              value={operationToSave?.operation1}
              autoComplete="none"
              onChange={handleChange}
              InputLabelProps={{
                shrink: operationToSave?.operation1 !== undefined && true,
              }}
              variant="outlined"
            />
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Code article"
              name="codeArticle"
              color="warning"
              // error={errorInput?.operation}
              value={operationToSave?.codeArticle}
              autoComplete="none"
              onChange={handleChange}
              InputLabelProps={{
                shrink: operationToSave?.codeArticle !== undefined && true,
              }}
              variant="outlined"
            />
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="idFamille"
              shrink={operationToSave?.idFamille !== undefined && true}
            >
              Code Famille
            </InputLabel>
            <Select
              value={operationToSave?.idFamille}
              onChange={handleChange}
              color="warning"
              inputProps={{
                name: "idFamille",
              }}
              native
            >
              <option value=""></option>
              {allFamilleOperations.length === 0 ? (
                <option disabled>Loading ...</option>
              ) : (
                allFamilleOperations.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.codeFamille}
                  </option>
                ))
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="idOperationIrm"
              shrink={CheckAttr(operationToSave?.idOperationIrm)}
            >
              Opération IRM
            </InputLabel>
            <Select
              value={operationToSave?.idOperationIrm}
              onChange={handleChange}
              color="warning"
              inputProps={{
                name: "idOperationIrm",
              }}
              native
            >
              <option value=""></option>
              {isReceiveOperationIRM ? (
                <option disabled>Loading ...</option>
              ) : (
                irmOperation.map((item) => (
                  <option key={item?.Operation_ID} value={item?.Operation_ID}>
                    {item?.Operation_Libelle}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}></Grid>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            htmlFor="idClientIrm"
            shrink={CheckAttr(operationToSave?.idClientIrm)}
          >
            Société de Facturation
          </InputLabel>
          <Select
            value={idSocieteFact}
            onChange={handleChangeSociete}
            color="warning"
            inputProps={{
              name: "idSocieteFact",
            }}
            native
          >
            <option value=""></option>
            {allSociete.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.libelle}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          color="warning"
          size="large"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ textTransform: "none" }}
          onClick={handleSave}
        >
          {!isClickSave ? (
            "Enregistrer"
          ) : (
            <CircularProgress color="inherit" size={18} />
          )}
        </Button>
      </Box>{" "}
      <br />
      <div>{messageValidation}</div>
    </Box>
  );
};

export default OperationForm;
