import { useState, useEffect } from 'react';
import axios from 'axios';
import BudgetInte from '../../../interface/Budget';
import { Box, Paper, Pagination, InputBase, styled, Stack, Divider, LinearProgress, IconButton, Typography, Breadcrumbs } from '@mui/material';
import { TableHead, TableRow, TableBody, TableContainer, Table, Container, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { Button } from '@mui/material';
import { Grid } from '@mui/material';
import Link from '@mui/material/Link';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SearchIcon from '@mui/icons-material/Search';
import GradingIcon from '@mui/icons-material/Grading';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BudgetRow from './BudgetRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function Budget() {
    const urlApi = process.env.REACT_APP_BACKEND_URL as string;
    const [rows, setRows] = useState<BudgetInte[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnImportClicked, setIsBtnImportClicked] = useState<boolean>(false);
    const [isImportBudget, setIsImportBudget] = useState<boolean>(false);
    const [reloadList, setReloadList] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('client');
    const [keyWord, setKeyWord] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalData, setTotalData] = useState<number>(0);
    const [apiFiltre, setApiFiltre] = useState<string>('filtre');
    const [apiKeyWord, setApiKeyWord] = useState<string>('search');
    const [encodedFile, setEncodedFile] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${urlApi}suivirenta/budget/${apiFiltre}/${apiKeyWord}/${rowsPerPage}/${page}`)
            .then((res) => {
                setRows(res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error.message);
                setIsLoading(false);
            });


        axios.get(`${urlApi}suivirenta/budget/count/${apiFiltre}/${apiKeyWord}`)
            .then((res) => {
                setTotalData(res.data);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, [rowsPerPage, page, apiKeyWord, reloadList]);

    const showToastSuccess = (msg: string): void => {
        toast.success(msg, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        });
    };

    const showMessageToastError = (msg: string): void => {
        toast.error(msg, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        });
    };

    const pagination = () => {
        return (
            !isLoading && (
                <Stack spacing={2} direction="row">
                    <Stack spacing={2} direction="row">
                        <InputLabel>Lignes par page :</InputLabel>
                        <FormControl variant="standard" sx={{ minWidth: 40 }}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                color="warning"
                                value={rowsPerPage}
                                onChange={(event) => setRowsPerPage(event.target.value as number)}
                                defaultValue={5}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    {
                        totalData > rowsPerPage && (
                            <Pagination
                                count={Math.ceil(totalData / rowsPerPage)}
                                color="standard"
                                showFirstButton={true}
                                showLastButton={true}
                                hideNextButton={true}
                                hidePrevButton={true}
                                defaultPage={page}
                                page={page}
                                onChange={(event, value) => setPage(value)} />
                        )
                    }
                </Stack>
            )
        );
    };

    const handleChangeCherche = (): void => {
        if (filter !== '') {
            setApiFiltre(filter);
            setPage(1);
        }
        if (keyWord !== '') {
            setApiKeyWord(keyWord);
        }
        else {
            setApiKeyWord('search');
        }
    }

    // File converti to BASE64..........
    const handleChangeFile = async (event) => {
        const file = event.target.files[0];
        const base64Front = await convertBase64(file);

        console.log(base64Front as any);

        setEncodedFile(base64Front as any);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleImportBudget = () => {
        setIsImportBudget(true);
        const data = { file: encodedFile };
        axios
            .post(`${urlApi}import/customer/budget`, data)
            .then((res) => {
                console.log(res.data);
                setIsImportBudget(false);
                setIsBtnImportClicked(false);
                setReloadList(!reloadList);
                showToastSuccess("L'importation a été effectué avec succès.");
            })
            .catch((error) => {
                console.log(error.message);
                setIsImportBudget(false);
                showMessageToastError("Une erreure s'est produite, merci de ressayer.");
            });
    };

    const handleClose = () => {
        setIsBtnImportClicked(false);
    };

    return (
        <>
            <Container maxWidth="xl">
                <Box mt={4} mb={2}>
                    <Stack sx={{ position: "relative" }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant="h5">
                                <GradingIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Budget
                            </Typography>
                            <Typography variant="h6">
                                <AutoStoriesIcon sx={{ position: 'absolute', top: '3px' }} fontSize="medium" />
                                <Box ml={3.5}>Budget Par Client</Box>
                            </Typography>
                        </Breadcrumbs>
                        <Typography sx={{ display: 'flex', position: "absolute", right: "2%" }}>
                            <FormControl sx={{ minWidth: 220 }}>
                                <InputLabel id="demo-simple-select-label" color="warning" sx={{ mt: -1 }}>Chercher par ...</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter}
                                    size="small"
                                    color="warning"
                                    label="Age"
                                    onChange={(e) => setFilter(e.target.value as string)}
                                >
                                    <MenuItem value="pays">Pays</MenuItem>
                                    <MenuItem value="bu">Bu</MenuItem>
                                    <MenuItem value="client">Client</MenuItem>
                                </Select>
                            </FormControl>
                            <InputBase
                                sx={{ ml: 4, flex: 1 }}
                                value={keyWord}
                                placeholder="Taper quelque chose ..."
                                onChange={(e) => setKeyWord(e.target.value as string)}
                                fullWidth
                            />
                            <IconButton sx={{ p: '10px' }} aria-label="search"
                                onClick={handleChangeCherche}>
                                <SearchIcon />
                            </IconButton>
                        </Typography>
                    </Stack>
                </Box>
                <Divider sx={{ my: 4 }} />
                <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                    <Grid item xs={4} sm={6} md={6.5}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                color="inherit"
                                onClick={() => setIsBtnImportClicked(true)}
                            >
                                <FileUploadIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Import budget
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="http://api.parcoursclients.madacontact.com/public/canvas_budget_par_client.xlsx"
                            >
                                <DownloadIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Télécharger le canvas
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={4} sm={4} md={5.5}>
                        {pagination()}
                    </Grid>
                </Grid>
                <Paper elevation={3}>
                    <TableContainer component={Paper} style={{ marginTop: 20 }}>
                        <Table sx={{ maxHeight: 500 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Pays</StyledTableCell>
                                    <StyledTableCell align="center">Bu</StyledTableCell>
                                    <StyledTableCell align="center">Client</StyledTableCell>
                                    <StyledTableCell align="center">Date Debut</StyledTableCell>
                                    <StyledTableCell align="center">Date Fin</StyledTableCell>
                                    <StyledTableCell align="center">Ca Budget</StyledTableCell>
                                    <StyledTableCell align="center">Taux Marge Budget</StyledTableCell>
                                    <StyledTableCell align="center">Marge Valeur Budget</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                    <StyledTableCell align="center" colSpan={12}>
                                        <LinearProgress color="warning" />
                                        <Box my={2}><LinearProgress color="warning" /></Box>
                                    </StyledTableCell>
                                ) : (
                                    rows.length > 0 ? (
                                        rows.map((row) => (
                                            <StyledTableRow key={row.id}>
                                                <BudgetRow row={row} />
                                            </StyledTableRow>))
                                    ) : (
                                        <StyledTableCell colSpan={12} align="center">
                                            Aucun resultat a été trouvé ...
                                        </StyledTableCell>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>
                <Box display="flex" mt={3} mr={6}>
                    {pagination()}
                </Box>
                <br></br>
            </Container>
            <Dialog open={isBtnImportClicked} onClose={handleClose}>
                <DialogTitle>Import Budget</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuillez choisir ici votre fichier.
                    </DialogContentText>
                    <DialogContentText>
                        A noter que le fichier à importer doit être au format excel (.xlsx, ...).
                    </DialogContentText>
                    Parcourir les fichiers : &nbsp;
                    <Typography display="initial">
                        <input
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={handleChangeFile}
                        />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='inherit'>Annuler</Button>
                    <Button
                        onClick={handleImportBudget}
                        color='warning'
                    >
                        {!isImportBudget ? 'Importer' : <CircularProgress color="warning" size={16} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Budget;