import { useState, useEffect, Fragment } from "react";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { TextField } from '@material-ui/core';
import Button from '@mui/material/Button';
import dateFormat from 'dateformat';
import { styled } from '@mui/material';
import { DataSaisieManager } from "../../../interface/DataSaisieManager";
import { Reducers } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

// Message d'alerte (Toaster message)
const showToastSuccess = () => {
    toast.success('Mise à jour effectué !', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const HeureAgentRow = (props) => {
    const { row, refresh, setRefresh } = props;

    const urlApi = process.env.REACT_APP_BACKEND_C_SHARPS_URL as string;

    const logger = useSelector<Reducers>((state) => state.logger);

    const [data, setData] = useState<DataSaisieManager | any>();
    const [isClickSave, setIsClickSave] = useState<number>(0);

    useEffect(() => {
        setData(row);
    }, []);

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        setData({
            ...data,
            id: Number(row?.id),
            [name]: value,
        });
    };

    // Fonction qui met à jour un agent selectionné
    const editSaisieManager = () => {
        if (data?.id) {
            setIsClickSave(data?.id);
            axios.put(`${urlApi}api/saisie/manager/update`, data, {
                headers: {
                    Authorization: `Bearer ${(logger as any)?.token}`,
                },
            }).then((res) => {
                showToastSuccess();
                setIsClickSave(0);
                setRefresh(!refresh);
            }).catch((error) => {
                console.log(error.message);
                showMessageToastError();
                setIsClickSave(0);
            });
        }
    };

    return (
        <Fragment>
            <StyledTableCell align="center">
                {data?.pays}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.bu}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.client}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.operation}
            </StyledTableCell>
            <StyledTableCell align="center">
                {dateFormat(data?.date_saisie, 'mm/dd/yyyy')}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.heuresprod}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.heuresformation}
            </StyledTableCell>
            <StyledTableCell align="center">
                <TextField
                    name="heuresfomationfacturees"
                    type="number"
                    value={data?.heuresfomationfacturees}
                    onChange={(e) => handleChangeInput(e)}
                    placeholder="heures formation facture"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ style: { fontSize: 12 } }}
                />
            </StyledTableCell>
            <StyledTableCell align="center">
                <TextField
                    name="actes"
                    type="number"
                    value={data?.actes}
                    onChange={(e) => handleChangeInput(e)}
                    placeholder="Actes ..."
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ style: { fontSize: 12 } }}
                />
            </StyledTableCell>
            <StyledTableCell align="center">
                <TextField
                    name="dmt"
                    type="number"
                    value={data?.dmt}
                    onChange={(e) => handleChangeInput(e)}
                    placeholder="dmt ..."
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ style: { fontSize: 12 } }}
                />
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.login}
            </StyledTableCell>
            <StyledTableCell align="center">
                <Button
                    variant="outlined"
                    color="warning"
                    size="small"
                    style={{ textTransform: 'none' }}
                    onClick={editSaisieManager}
                >
                    {
                        isClickSave !== data?.id ?
                            'Enregistrer'
                            :
                            <CircularProgress color="inherit" size={18} />
                    }
                </Button>
            </StyledTableCell>
        </Fragment>
    )
}

export default HeureAgentRow;
