import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnUpdate: {
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.up('sm')]: {
                justifyContent: "left",
                marginLeft: 40
            },
        },

        paginationMarge: {
            marginTop: theme.spacing(2)
        },

        rowPerPageInput: {
            marginTop: '-3px'
        },

        topPagination: {
            display: "flex",
            justifyContent: "right",
            marginTop: 20,
            [theme.breakpoints.up('sm')]: {
                marginTop: 0,
            }
        },

        bottomPagination: {
            display: "flex",
            justifyContent: "left",
        },

        filterInput: {
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 10,
            [theme.breakpoints.up('sm')]: {
                marginTop: 0,
                marginBottom: 0,
                justifyContent: "right",
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 10,
                marginBottom: 0,
            }
        },

        searchInput: {
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.up('sm')]: {
                justifyContent: "right",
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 10,
                marginBottom: 0
            }
        }
    })
);