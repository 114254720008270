import {
  Box,
  Paper,
  Pagination,
  styled,
  Stack,
  Divider,
  LinearProgress,
  IconButton,
  Typography,
  Grid,
  Breadcrumbs,
} from "@mui/material";
import {
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  Container,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import GradingIcon from '@mui/icons-material/Grading';
import axios from "axios";
import dateFormat from "dateformat";
import { DataTarif } from "../../../../interface/DataTarif";
import { Reducers } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { useStyles } from "../style";
import { toast } from 'react-toastify';

toast.configure();

// Variable constante
const urlApi = process.env.REACT_APP_BACKEND_URL as string;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#777",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ListeTarif = () => {
  const logger = useSelector<Reducers>((state) => state.logger);
  const [tarif, setTarif] = useState<DataTarif[]>([]);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResetInput, setIsResetInput] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<number>(0);
  const [rowsParPage, setRowsParPage] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>('');
  const [keyWord, setKeyWord] = useState<string>('');

  const classes = useStyles();

  const isSearchMode = (filter.length > 0 && keyWord.length > 2) && true;

  const data = {
    filter: isSearchMode ? filter : 'none',
    keyword: isSearchMode ? keyWord : 'none',
    token: (logger as any)?.token
  };

  const showMessageWarning = (param: string) => {
    toast.warning(param, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const receiveData = () => {
    // Activer un loader dans notre page
    setIsLoading(true);

    // Requete axios pour avoir une liste des tarifs dans API
    axios.post(`${urlApi}list/tarif/${rowsParPage}/${page}`, data).then((res) => {
      if (res?.data) {
        setTotalData(res.data[0]);
        Array.isArray(res.data[1]) && setTarif(res.data[1]);

        // Desactiver un loader dans notre page
        setIsLoading(false);
      } else {
        setIsEmpty(true);
      }
    }).catch((error) => {
      console.log(error.message);
    });
  };

  useEffect(() => {
    receiveData();
  }, [rowsParPage, page, isResetInput]);

  const handleChange = (e): void => {
    const { name, value } = e.target;
    name === "filter" && setFilter(value);
    name === "keyWord" && setKeyWord(value);

    if (value.length === 0) {
      setPage(1);
      setIsResetInput(!isResetInput);
    }
  };

  const handleSearch = () => {
    if (isSearchMode) {
      receiveData();
    } else {
      showMessageWarning('SVP! Merci de remplir les Champs de recherche.');
    }
  };

  return (
    <Container maxWidth="xl">
      <Box mt={4} mb={2}>
        <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
          <Grid item xs={4} sm={6} md={4}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ position: "relative" }}>
              <Typography variant="h5">
                <GradingIcon sx={{ position: 'absolute', top: '3px' }} fontSize="inherit" />
                <Box ml={3.5}>Historiques des tarifs</Box>
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} sm={4} md={5.5}>
            <Box className={classes.filterInput}>
              <FormControl sx={{ minWidth: 220 }}>
                <InputLabel id="filter input" color="warning" sx={{ mt: -1 }}>Chercher par ...</InputLabel>
                <Select
                  labelId="filter input"
                  id="demo-simple-select"
                  name="filter"
                  value={filter}
                  onChange={handleChange}
                  size="small"
                  color="warning"
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="pays">Pays</MenuItem>
                  <MenuItem value="bu">Bu</MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="operation">Operation</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={4} sm={2} md={2.5}>
            <Box className={classes.searchInput}>
              <TextField
                sx={{ width: "220px" }}
                name="keyWord"
                value={keyWord}
                onChange={handleChange}
                placeholder="Taper quelque chose ..."
                size="small"
                color="warning"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton aria-label="search" edge="end" onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: 4 }} />
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Box mt={1} mb={2}>
            <Stack direction="row" spacing={2}>
              <Stack direction="row" spacing={2} sx={{ marginTop: "5px" }}>
                <InputLabel sx={{ marginTop: "4px" }}>
                  Lignes par page :
                </InputLabel>
                <FormControl variant="standard" sx={{ minWidth: 40 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    color="warning"
                    name="rowsParPage"
                    value={rowsParPage}
                    onChange={(event) => setRowsParPage(event.target.value as number)}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              {totalData > rowsParPage && (
                <Pagination
                  count={Math.ceil(totalData / rowsParPage)}
                  color="standard"
                  showFirstButton={true}
                  showLastButton={true}
                  hideNextButton={true}
                  hidePrevButton={true}
                  siblingCount={0}
                  boundaryCount={1}
                  defaultPage={page}
                  onChange={(event, value) => setPage(value)}
                />
              )}
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Paper elevation={3}>
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table
            sx={{ maxHeight: 500 }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Pays</StyledTableCell>
                <StyledTableCell align="center">Bu</StyledTableCell>
                <StyledTableCell align="center">Client</StyledTableCell>
                <StyledTableCell align="center">Opération</StyledTableCell>
                <StyledTableCell align="center">
                  Date de debut
                </StyledTableCell>
                <StyledTableCell align="center">Date de fin</StyledTableCell>
                <StyledTableCell align="center">Tarif heure</StyledTableCell>
                <StyledTableCell align="center">Tarif acte</StyledTableCell>
                <StyledTableCell align="center">
                  Tarif formation
                </StyledTableCell>
                <StyledTableCell align="center">
                  Tarif heure dimanche
                </StyledTableCell>
                <StyledTableCell align="center">
                  Tarif acte dimanche
                </StyledTableCell>
                <StyledTableCell align="center">
                  Tarif formation dimanche
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <StyledTableCell align="center" colSpan={12}>
                    <Box my={2}>
                      <LinearProgress color="warning" />
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ) : isEmpty ? (
                <TableRow>
                  <StyledTableCell align="center" colSpan={12}>
                    Auccun resultat a été trouvé.
                  </StyledTableCell>
                </TableRow>
              ) : (
                tarif?.map((row) => (
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell align="center">
                      {row?.pays}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.bu}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.client}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.operation}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dateFormat(row?.date_debut, "dd/mm/yyyy")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dateFormat(row?.date_fin, "dd/mm/yyyy")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.tarifheure}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.tarifacte}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.tarifformation}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.tarifheuredimanche}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.tarifactedimanche}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.tarifformationdimanche}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {
        <Box mt={4} mb={10} mr={3}>
          <Stack direction="row" sx={{ position: "relative" }}>
            {!isEmpty && (
              <Stack
                direction="row"
                spacing={6}
                sx={{ position: "absolute", right: "2%" }}
              >
                {totalData > rowsParPage && (
                  <Pagination
                    count={Math.ceil(totalData / rowsParPage)}
                    color="standard"
                    showFirstButton={true}
                    showLastButton={true}
                    hideNextButton={true}
                    siblingCount={0}
                    boundaryCount={1}
                    hidePrevButton={true}
                    defaultPage={page}
                    onChange={(event, value) => setPage(value)}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      }
    </Container>
  );
};

export default ListeTarif;
