import { useEffect, useState } from "react";
import {
    Box, Paper, Pagination, TextField, styled, Stack, Divider, LinearProgress, Grid,
    InputAdornment, IconButton, Typography, Breadcrumbs, TableHead, TableRow, TableBody,
    TableContainer, Table, Container, FormControl, Select, InputLabel, MenuItem, Button
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import GradingIcon from '@mui/icons-material/Grading';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SearchIcon from '@mui/icons-material/Search';
import CheckAttr from "../../../../function/CheckAttr";
import DataFamilleOperation from "../../../../interface/DataFamilleOperation";
import FamilleOperationRow from "./FamilleOperationRow";
import axios from "axios";
import { Reducers } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { Add } from "@material-ui/icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const showMessageToastError = (): void => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const showMessageToastSuccess = (msg: string): void => {
    toast.success(msg,
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
};

const showMessageWarning = (param: string) => {
    toast.warning(param, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const FamilleOperation = () => {
    const urlApi = process.env.REACT_APP_BACKEND_URL as string;
    const logger = useSelector<Reducers>((state) => state.logger);

    const [filter, setFilter] = useState<string>('');
    const [keyword, setKeyword] = useState<string>('');
    const [paysFactSearch, setPaysFactSearch] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isClickSave, setIsClickSave] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);
    const [totalData, setTotalData] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(1);
    const [familleOperations, setFamilleOperations] = useState<DataFamilleOperation[]>([]);
    const [familleOperationToSave, setFamilleOperationToSave] = useState<DataFamilleOperation>();
    const [errorSearch, setErrorSearchInput] = useState<any>({
        filter: false,
        keyword: false
    });
    const [errorInput, setErrorInput] = useState<any>({
        libelle: false
    });

    const data = {
        filter: CheckAttr(filter) ? filter : 'empty',
        keyWord: CheckAttr(keyword) ? keyword : 'empty',
        rowPerPage: rowsPerPage,
        page: page,
        token: (logger as any)?.token
    };

    const receiveData = () => {
        // Activer un loader dans notre page
        setIsLoading(true);
        axios.post(`${urlApi}parametrage/famille/operation/list`, data)
            .then(res => {
                const { totalCount, datas } = res.data;

                if (totalCount > 0) {
                    totalCount && setTotalData(totalCount);
                    Array.isArray(datas) && setFamilleOperations(datas);
                } else {
                    setTotalData(0);
                }
            }).catch((error) => {
                console.log(error.message);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        receiveData();
    }, [rowsPerPage, page, refresh]);

    const handleChange = (e): void => {
        setKeyword(e.target.value);

        if (e.target.value.length === 0) {
            setPage(1);
            setRefresh(!refresh);
        }
    };

    const resetAllRequiredInput = (): void => {
        setErrorInput((prev: any) => ({
            ...prev,
            libelle: false
        }));
    }

    const handleSave = (): void => {
        // reinitialiser à false les valeurs des attributs qui controle les champs obligatoire
        resetAllRequiredInput();

        if (!CheckAttr(familleOperationToSave?.libelle)) {
            setErrorInput((prev: any) => ({ ...prev, libelle: true }));
            showMessageWarning("Merci de remplir le libelle du famille operation !");
        } else {
            setIsClickSave(true);
            //  create new pays de facturation
            axios.post(`${urlApi}parametrage/famille/operation/save`, familleOperationToSave)
                .then((res) => {
                    showMessageToastSuccess(res.data);
                    setFamilleOperationToSave({
                        libelle: '',
                        codeFamille: ''
                    } as DataFamilleOperation);

                    setRefresh(!refresh);
                }).catch((e) => {
                    alert(e.message);
                    showMessageToastError();
                }).finally(() => {
                    setIsClickSave(false);
                });
        }
    }

    const handleSearch = (): void => {
        // reinitialiser à false les valeurs des attributs qui controle les champs obligatoire
        setErrorSearchInput((prev: any) => ({
            ...prev,
            filter: false,
            keyword: false
        }));

        if (CheckAttr(filter) && CheckAttr(keyword)) {
            receiveData();
        } else {
            if (!CheckAttr(filter)) {
                setErrorSearchInput((prev: any) => ({ ...prev, filter: true }));
            }
            if (!CheckAttr(keyword)) {
                setErrorSearchInput((prev: any) => ({ ...prev, keyword: true }));
            }
            showMessageWarning('SVP! Merci de remplir les Champs de recherche.');
        }
    };

    const handleChangeForSave = (e): void => {
        const { name, value } = e.target;
        setFamilleOperationToSave({
            ...familleOperationToSave as DataFamilleOperation,
            [name]: value
        });
    }

    const pagination: JSX.Element | boolean = (!isLoading && (totalData > rowsPerPage)) && (
        <Stack spacing={2} direction="row">
            <InputLabel sx={{ marginTop: '8px' }}>Lignes par page :</InputLabel>
            <FormControl variant="standard" sx={{ minWidth: 40 }}>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    color="warning"
                    value={rowsPerPage}
                    onChange={(e) => setRowsPerPage(e.target.value as number)}
                    defaultValue={5}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
            </FormControl>
            <Pagination
                count={Math.ceil(totalData / rowsPerPage)}
                color="standard"
                showFirstButton={true}
                showLastButton={true}
                hideNextButton={true}
                hidePrevButton={true}
                siblingCount={0}
                boundaryCount={1}
                defaultPage={page}
                onChange={(event, value) => setPage(value)} />
        </Stack>
    );

    return (
        <Container maxWidth="xl">
            <Box mt={4} mb={2}>
                <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant="h5">
                                <GradingIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Paramètrage
                            </Typography>
                            <Typography variant="h6">
                                <AutoStoriesIcon sx={{ position: 'absolute', top: '3px' }} fontSize="medium" />
                                Famille opération
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                            <FormControl sx={{ minWidth: 220 }}>
                                <InputLabel id="demo-simple-select-label" color="warning" sx={{ mt: -1 }}>Chercher par ...</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    error={errorSearch?.filter}
                                    value={filter}
                                    size="small"
                                    color="warning"
                                    label="Age"
                                    onChange={(e) => setFilter(e.target.value as string)}
                                >
                                    <MenuItem value="libelle">Famille opération</MenuItem>
                                    <MenuItem value="codeFamille">Code famille</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                sx={{ width: "200px" }}
                                name="keyWord"
                                value={keyword}
                                error={errorSearch?.keyword}
                                onChange={handleChange}
                                placeholder="Taper quelque chose ..."
                                size="small"
                                color="warning"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton aria-label="search" edge="end" onClick={handleSearch}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                <Grid item xs={4} sm={3} md={6}>
                    <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'left' }}>
                        <Button
                            variant="outlined"
                            startIcon={<Add />}
                            color="warning"
                            size="small"
                            sx={{ textTransform: "none" }}
                            onClick={handleSave}
                        >
                            {!isClickSave ? (
                                'Ajouter'
                            ) : (
                                <CircularProgress color="inherit" size={18} />
                            )}
                        </Button>
                        <TextField
                            sx={{ width: "200px" }}
                            name="libelle"
                            error={errorInput?.libelle}
                            value={familleOperationToSave?.libelle}
                            onChange={handleChangeForSave}
                            placeholder="Famille opera ..."
                            size="small"
                            color="warning"
                        />
                        <TextField
                            sx={{ width: "200px" }}
                            name="codeFamille"
                            // error={errorInputAddValidation}
                            value={familleOperationToSave?.codeFamille}
                            onChange={handleChangeForSave}
                            placeholder="Code famille ..."
                            size="small"
                            color="warning"
                        />
                    </Stack>
                </Grid>
                <Grid item xs={4} sm={3} md={6}>
                    <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'right' }}>
                        {pagination}
                    </Stack>
                </Grid>
            </Grid>
            <Paper elevation={3}>
                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                    <Table sx={{ maxHeight: 500 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Famille operation</StyledTableCell>
                                <StyledTableCell align="center">Code famille</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <StyledTableCell align="center" colSpan={3}>
                                    <LinearProgress color="warning" />
                                    <Box my={2}><LinearProgress color="warning" /></Box>
                                </StyledTableCell>
                            ) : (
                                totalData > 0 ? (
                                    familleOperations.map((familleOperation) => (
                                        <StyledTableRow key={familleOperation?.id}>
                                            <FamilleOperationRow
                                                familleOperation={familleOperation}
                                                refresh={refresh}
                                                setRefresh={setRefresh}
                                                setPage={setPage}
                                            />
                                        </StyledTableRow>))
                                ) : (
                                    <StyledTableCell align="center">
                                        Aucun resultat a été trouvé ...
                                    </StyledTableCell>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            <Box justifyContent="left" display="flex" mb={5} mt={2} ml={6}>
                {pagination}
            </Box>
        </Container>
    );
};

export default FamilleOperation;
