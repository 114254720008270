import { Box, Paper, Pagination, InputBase, styled, Stack, Divider, LinearProgress, IconButton, Typography, Breadcrumbs } from '@mui/material';
import { TableHead, TableRow, TableBody, TableContainer, Table, Container } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useState, useEffect } from 'react';
import GradingIcon from '@mui/icons-material/Grading';
import LanguageIcon from '@mui/icons-material/Language';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { Reducers } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';

export interface DataPays {
    id: number;
    pays: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const Pays = () => {

    const urlApi = process.env.REACT_APP_BACKEND_URL as string;

    const logger = useSelector<Reducers>((state) => state.logger);

    const [searched, setSearched] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [nb, setNb] = useState(0);
    const [rows, setRows] = useState<DataPays[]>([]);

    useEffect(() => {
        const data = { token: (logger as any)?.token };

        if (searched === '') {
            axios.post(`${urlApi}parametrage/pays/${page}/empty`, data).then((res) => {
                setRows(res.data[0]);
                setNb(res.data[1]);
                setIsLoading(false);
            });
        } else {
            axios.post(`${urlApi}parametrage/pays/${page}/${searched}`, data).then((res) => {
                setRows(res.data[0]);
                setNb(res.data[1]);
                setIsLoading(false);
            });
        }
    }, [page, searched]);

    const handleChange = (event) => {
        event.preventDefault();
        setSearched(event.target.value);
    };

    return (
        <Container maxWidth="md">
            <Box mt={4} mb={2}>
                <Stack sx={{ position: "relative" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h5">
                            <GradingIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Paramètrage
                        </Typography>
                        <Typography variant="h6" sx={{ position: 'relative' }}>
                            <LanguageIcon sx={{ position: 'absolute', top: '3px' }} fontSize="medium" />
                            <Box ml={3.5}>Pays</Box>
                        </Typography>
                    </Breadcrumbs>
                    <Typography sx={{ display: 'flex', position: "absolute", right: "2%" }}>
                        <InputBase
                            sx={{ ml: 4, flex: 1 }}
                            value={searched}
                            placeholder="Chercher un pays"
                            onChange={handleChange}
                            fullWidth
                        />
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Typography>
                </Stack>
            </Box>
            <Divider sx={{ my: 4 }} />
            <Paper elevation={3}>
                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                    <Table sx={{ maxHeight: 500 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Pays</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableBody>
                                <StyledTableCell align="center">
                                    <LinearProgress color="warning" />
                                    <Box my={2}><LinearProgress color="warning" /></Box>
                                </StyledTableCell>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {rows.map((row) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell align="center">{row.pays}</StyledTableCell>
                                    </StyledTableRow>))}
                            </TableBody>)}
                    </Table>
                </TableContainer>
            </Paper>
            {
                nb > 7 && (
                    <Box justifyContent="right" display="flex" mt={3} mr={6}>
                        <Stack spacing={2}>
                            <Pagination
                                count={Math.ceil(nb / 7)}
                                color="primary"
                                showFirstButton={true}
                                showLastButton={true}
                                hideNextButton={true}
                                hidePrevButton={true}
                                defaultPage={page}
                                onChange={(event, value) => setPage(value)} />
                        </Stack>
                    </Box>)
            }
        </Container>
    )
}

export default Pays;
