import { useState, useEffect, Fragment } from "react";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { toast } from 'react-toastify';
import dateFormat from 'dateformat';
import { styled } from '@mui/material';
import { DataCout } from "../../../../interface/DataCout";
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        textAlign: 'center',
    },
}));

const ListeCoutRow = (props) => {
    const { row } = props;

    const [data, setData] = useState<DataCout>();

    useEffect(() => {
        setData(row);
    }, []);

    return (
        <Fragment>
            <StyledTableCell>
                {data?.pays}
            </StyledTableCell>
            <StyledTableCell>
                {data?.bu}
            </StyledTableCell>
            <StyledTableCell>
                {data?.client}
            </StyledTableCell>
            <StyledTableCell>
                {data?.operation}
            </StyledTableCell>
            <StyledTableCell>
                {dateFormat(data?.date_debut, 'dd-mm-yyyy')}
            </StyledTableCell>
            <StyledTableCell>
                {dateFormat(data?.date_fin, 'dd-mm-yyyy')}
            </StyledTableCell>
            <StyledTableCell>
                {data?.coutactivite}
            </StyledTableCell>
            <StyledTableCell>
                {data?.coutformation}
            </StyledTableCell>
        </Fragment>
    )
}

export default ListeCoutRow;
