import {Box, FormControl, InputLabel, Select} from '@material-ui/core';
import { useStyles } from './styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import BudgetInte from '../../interface/Budget';
import {  FC, useState, useEffect } from 'react';
import dateFormat from 'dateformat';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import { Player } from '@lottiefiles/react-lottie-player';
interface propsModificatioBudget {
    budgetRow?: BudgetInte;
}
const ModificationBudgetForm: FC<propsModificatioBudget> = ({
    budgetRow
}) => {
    const urlApi = process.env.REACT_APP_BACKEND_URL as string;
    const classes = useStyles();
    const [wait, setWait] = useState<boolean>(false);
    const [client, setClient] = useState<string>('');
    const [pays, setPays] = useState<string>(budgetRow ? budgetRow.pays ? budgetRow.pays : 'France' : 'France' );
    const [bu , setBu] = useState<string>('');
    const [ca , setCa] = useState<string>('');
    const [tx , setTx] = useState<string>('');
    const [marge , setMarge] = useState<string>('');
    const [dateDebut, setDateDebut] = useState<string>('');
    const [dateFin, setDateFin] = useState<string>('');
    const [budgetToSave, setBudgetToSave] = useState<BudgetInte>();

    const handleEnregistrer = () =>
    {
        setBudgetToSave({ pays: pays, id: budgetRow?.id, bu: bu, ca_budget: ca, client: client, date_debut: dateDebut, date_fin: dateFin,
            marge_valeur_budget: marge, tx_marge_budget: tx
        })
        
        // console.log(budgetToSave);
        /* axios.post(`${urlApi}suivirenta/budget/update/${budgetRow?.id}`,budgetToSave )
        .then((res) => {
            console.log(res.data);
        })
        .catch((error) => {
            console.log(error.message);
        }); */
    
       
    }
    useEffect(() => {
        if (budgetRow) {
            if (!budgetToSave){
                console.log(budgetRow.pays); 
                setClient(budgetRow.client ?? 'tsisy')
                setPays(budgetRow.pays ?? 'tsisy')
                  
                setBu(budgetRow.bu ?? 'tsisy')
                setCa(budgetRow.ca_budget ?? '')
                setTx(budgetRow.tx_marge_budget ?? '')
                setMarge(budgetRow.marge_valeur_budget ?? '')
                setDateDebut(budgetRow?.date_debut ? String(dateFormat(budgetRow?.date_debut, 'yyyy-mm-dd')) : 'tsisy')
                setDateFin(budgetRow?.date_debut ? String(dateFormat(budgetRow?.date_fin, 'yyyy-mm-dd'))  : 'tsisy')
            }
        }
        if (budgetToSave) {
            setWait(true);
            axios.post(`${urlApi}suivirenta/budget/update/${budgetRow?.id}`,budgetToSave )
            .then((res) => {
                setWait(false);
                console.log(res.data);
                window.location.reload(); 
            })
            .catch((error) => {
                setWait(false);
                console.log(error.message);
            })
        }

    }, [budgetToSave]);

    useEffect(() => {
        const tmp= Number(ca) * Number(tx);
        setMarge(String(tmp));
    },[ca , tx]);
    return (
        <Box className={classes.root}>
            { wait ? <Player
                  autoplay
                  loop
                  src="../../../../wait.json"
                  style={{
                      height: "400px",
                      width:"400px",
                      position: 'fixed',
                      top : "250px"
                  }}
              ></Player> : <></>}
            
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField id="outlined-basic" label="Client" variant="outlined" color="warning" 
                            value={client} 
                            onChange={(e) => setClient(e.target.value)}
                            />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="civilite">Pays</InputLabel>
                        <Select
                        label="Civilité *"
                        value={String(pays)}
                        onChange={(e) => setPays(String(e.target.value))}
                        >
                            <MenuItem  value="Madagascar">Madagascar</MenuItem >
                            <MenuItem  value="Maroc">Maroc</MenuItem >
                            <MenuItem  value="France">France</MenuItem >
                            <MenuItem  value="Niger">Niger</MenuItem >
                        </Select>
                    </FormControl>
                </Grid>
            </Grid> 
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField id="outlined-basic" label="BU" variant="outlined" color="warning"
                         value={bu} 
                         onChange={(e) => setBu(e.target.value)}
                         />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField id="outlined-basic" label="Ca Budget" variant="outlined" color="warning"
                         value={ca} 
                         onChange={(e) => setCa(e.target.value)}
                         />
                    </FormControl>
                </Grid>
            </Grid> 
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <InputLabel htmlFor="input-with-icon-adornment">
                        Date Debut
                    </InputLabel>
                    <input
                                name="dateCreate"
                                type="date"
                                defaultValue=""
                                color="warning"
                                style={{ borderRadius: '4px', border: '1px solid #AAAAAA', height: '55px', width: '365px', color: '#757575', fontSize: '17px' }}
                                value={dateDebut}
                                onChange={(e) => setDateDebut(String(e.target.value))}
                            />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel htmlFor="input-with-icon-adornment">
                        Date Fin
                    </InputLabel>
                    <input
                                name="dateCreate"
                                type="date"
                                defaultValue=""
                                color="warning"
                                value={dateFin}
                                onChange={(e) => setDateFin(String(e.target.value))}
                                style={{ borderRadius: '4px', border: '1px solid #AAAAAA', height: '55px', width: '365px', color: '#757575', fontSize: '17px' }}
                                // value={dateApplicatif}
                                // onChange={handleChangeDateApplicatif}
                            />
                </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField id="outlined-basic" label="Tx Marge Budget" variant="outlined" color="warning"
                         value={tx} 
                         onChange={(e) => setTx(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField id="outlined-read-only-input" label="Marge Valeur Budget" variant="outlined" color="warning"
                         InputProps={{
                            readOnly: true,
                          }}
                         value={marge}
                         />
                    </FormControl>
                </Grid>
            </Grid> 
            <Grid container spacing={2}>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>  
                    <Button variant="outlined" color="warning"
                        onClick={handleEnregistrer}
                        startIcon={<ModeEditIcon />}
                        style={{ border: '1px solid #AAAAAA', height: '45px', width: '165px', fontSize: '14px' }}
                     >
                        Enregistrer
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}
export default ModificationBudgetForm;