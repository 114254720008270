import { useEffect, useState } from "react";
import {
    Box, Paper, Pagination, TextField, styled, Stack, Divider, LinearProgress, Grid,
    InputAdornment, IconButton, Typography, Breadcrumbs, TableHead, TableRow, TableBody,
    TableContainer, Table, Container, FormControl, Select, InputLabel, MenuItem, Button
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import GradingIcon from '@mui/icons-material/Grading';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SearchIcon from '@mui/icons-material/Search';
import DataSocieteFacturation from "../../../../interface/DataSocieteFacturation";
import DataRef from "../../../../interface/DataRef";
import SocieteFacturationRow from "./SocieteFacturationRow";
import SocieteFacturationForm from "../../../forms/SocieteFacturationForm";
import CheckAttr from "../../../../function/CheckAttr";
import CustomModal from "../../../../commons/custom-modal/CustomModal";
import axios from "axios";
import { Reducers } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { Add } from "@material-ui/icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const showMessageWarning = (param: string) => {
    toast.warning(param, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const SocieteFacturation = () => {
    const urlApi = process.env.REACT_APP_BACKEND_URL as string;
    const logger = useSelector<Reducers>((state) => state.logger);

    const [filter, setFilter] = useState<string>('');
    const [keyword, setKeyword] = useState<string>('');
    const [paysFactSearch, setPaysFactSearch] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);
    const [totalData, setTotalData] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(1);
    const [societeFacturations, setSocieteFacturations] = useState<DataSocieteFacturation[]>([]);
    const [allPaysFacturations, setAllPaysFacturations] = useState<DataRef[]>([]);

    const data = {
        filter: CheckAttr(filter) ? filter : 'empty',
        keyWord: CheckAttr(keyword) ? keyword : 'empty',
        paysFactSearch: paysFactSearch,
        rowPerPage: rowsPerPage,
        page: page,
        token: (logger as any)?.token
    };

    useEffect(() => {
        axios.get(`${urlApi}get/all/pays-facturation`)
            .then(res => {
                Array.isArray(res.data) && setAllPaysFacturations(res.data);
            }).catch((error) => {
                console.log(error.message);
            });
    }, []);

    const receiveData = () => {
        // Activer un loader dans notre page
        setIsLoading(true);
        axios.post(`${urlApi}parametrage/societefacturation/list`, data)
            .then(res => {
                const { totalCount, datas } = res.data;

                if (totalCount > 0) {
                    totalCount && setTotalData(totalCount);
                    Array.isArray(datas) && setSocieteFacturations(datas);
                } else {
                    setTotalData(0);
                }
            }).catch((error) => {
                console.log(error.message);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        receiveData();
    }, [rowsPerPage, page, refresh]);

    const handleChange = (e): void => {
        setKeyword(e.target.value);

        if (filter === 'pays_facturation') {
            const paysFactContainsKeyWord = allPaysFacturations?.filter(paysFact => paysFact?.libelle?.toLocaleLowerCase()?.replace(/ /g, '')?.includes(e.target.value?.toLocaleLowerCase()?.replace(/ /g, '')));

            const tabIdPaysFact: number[] = [];
            for (const i in paysFactContainsKeyWord) {
                tabIdPaysFact?.push(paysFactContainsKeyWord[i]?.id as number);
            }

            setPaysFactSearch(tabIdPaysFact);
        }

        if (e.target.value.length === 0) {
            setPage(1);
            setRefresh(!refresh);
        }
    };

    const handleSearch = (): void => {
        if (CheckAttr(filter) && CheckAttr(keyword)) {
            receiveData();
        } else {
            showMessageWarning('SVP! Merci de remplir les Champs de recherche.');
        }
    };

    const handleAddSocieteFacuration = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const pagination: JSX.Element | boolean = (!isLoading && (totalData > rowsPerPage)) && (
        <Stack spacing={2} direction="row">
            <InputLabel sx={{ marginTop: '8px' }}>Lignes par page :</InputLabel>
            <FormControl variant="standard" sx={{ minWidth: 40 }}>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    color="warning"
                    value={rowsPerPage}
                    onChange={(e) => setRowsPerPage(e.target.value as number)}
                    defaultValue={5}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
            </FormControl>
            <Pagination
                count={Math.ceil(totalData / rowsPerPage)}
                color="standard"
                showFirstButton={true}
                showLastButton={true}
                hideNextButton={true}
                hidePrevButton={true}
                siblingCount={0}
                boundaryCount={1}
                defaultPage={page}
                onChange={(event, value) => setPage(value)} />
        </Stack>
    );

    return (
        <Container maxWidth="xl">
            <Box mt={4} mb={2}>
                <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant="h5">
                                <GradingIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Paramètrage
                            </Typography>
                            <Typography variant="h6">
                                <AutoStoriesIcon sx={{ position: 'absolute', top: '3px' }} fontSize="medium" />
                                Société de facturation
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                            <FormControl sx={{ minWidth: 220 }}>
                                <InputLabel id="demo-simple-select-label" color="warning" sx={{ mt: -1 }}>Chercher par ...</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter}
                                    size="small"
                                    color="warning"
                                    label="Age"
                                    onChange={(e) => setFilter(e.target.value as string)}
                                >
                                    <MenuItem value="libelle">Société de facturation</MenuItem>
                                    <MenuItem value="pays_facturation">Pays de facturation</MenuItem>
                                    <MenuItem value="activite">Activité</MenuItem>
                                    <MenuItem value="adresse">Adresse</MenuItem>
                                    <MenuItem value="Ville">ville</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                sx={{ width: "220px" }}
                                name="keyWord"
                                value={keyword}
                                onChange={handleChange}
                                placeholder="Taper quelque chose ..."
                                size="small"
                                color="warning"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton aria-label="search" edge="end" onClick={handleSearch}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                <Grid item xs={4} sm={3} md={6}>
                    <Box ml={6}>
                        <Button
                            variant="outlined"
                            startIcon={<Add />}
                            color="warning"
                            sx={{ textTransform: "none" }}
                            onClick={handleAddSocieteFacuration}
                        >
                            Ajouter
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={4} sm={3} md={6}>
                    <Box justifyContent="right" display="flex" mr={6}>
                        {pagination}
                    </Box>
                </Grid>
            </Grid>
            <Paper elevation={3}>
                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                    <Table sx={{ maxHeight: 500 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Société de facturation</StyledTableCell>
                                <StyledTableCell align="center">Pays de facturation</StyledTableCell>
                                <StyledTableCell align="center">Activité</StyledTableCell>
                                <StyledTableCell align="center">Forme Juridique</StyledTableCell>
                                <StyledTableCell align="center">Capital</StyledTableCell>
                                <StyledTableCell align="center">Adresse</StyledTableCell>
                                <StyledTableCell align="center">Code postal</StyledTableCell>
                                <StyledTableCell align="center">Ville</StyledTableCell>
                                <StyledTableCell align="center">Registre commerce</StyledTableCell>
                                <StyledTableCell align="center">Identifiant fiscal</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <StyledTableCell align="center" colSpan={11}>
                                    <LinearProgress color="warning" />
                                    <Box my={2}><LinearProgress color="warning" /></Box>
                                </StyledTableCell>
                            ) : (
                                totalData > 0 ? (
                                    societeFacturations.map((societeFacturation) => (
                                        <StyledTableRow key={societeFacturation?.id}>
                                            <SocieteFacturationRow
                                                societeFacturation={societeFacturation}
                                                allPaysFacturations={allPaysFacturations}
                                                refresh={refresh}
                                                setRefresh={setRefresh}
                                                setPage={setPage}
                                            />
                                        </StyledTableRow>))
                                ) : (
                                    <StyledTableCell align="center">
                                        Aucun resultat a été trouvé ...
                                    </StyledTableCell>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            <Box justifyContent="left" display="flex" mb={5} mt={2} ml={6}>
                {pagination}
            </Box>
            <CustomModal
                title="Ajouter une société de facturation"
                onClose={handleClose}
                open={openModal}
            >
                <SocieteFacturationForm
                    allPaysFacturations={allPaysFacturations}
                    setOpenModal={setOpenModal}
                    setRefresh={setRefresh}
                    refresh={refresh}
                />
            </CustomModal>
        </Container>
    );
}

export default SocieteFacturation;
