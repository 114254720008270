import { useState } from "react";
import { Box, Toolbar, IconButton, AppBar, Drawer, CssBaseline, Container, styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ContentNavabar from "./ContentNavbar";
import SideMenuResponsive from "./SideMenuResponsive";

const drawerWidth = 220;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-start",
  paddingTop: theme.spacing(1),
  "@media all": {
    minHeight: 50,
  },
}));

interface Props {
  window?: () => Window;
}

const Navbar = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="static" style={{ backgroundColor: "#F8F8F8" }}>
        <Container maxWidth="xl">
          <StyledToolbar disableGutters>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" }, color: "#777" }}
            >
              <MenuIcon />
            </IconButton>
            <ContentNavabar />
          </StyledToolbar>
        </Container>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <SideMenuResponsive />
          </Drawer>
        </Box>
      </AppBar>
    </Box>
  );
};

export default Navbar;
