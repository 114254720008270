// import { UserInformation } from "../../components/content/interface/UserInformation";
export interface UserInformation {
  token: string;
  profil: string;
  departement: string;
  login: string;
  name: string;
  firstname: string;
}

export const loggerUserReducer = (
  state = null,
  action
): UserInformation | null => {
  switch (action.type) {
    case 'LOGGING_USER':
      return {
        token: action.payload.token,
        profil: action.payload.profil,
        departement: action.payload.departement,
        login: action.payload.login,
        name: action.payload.name,
        firstname: action.payload.firstname
      };
    case 'LOGGOUT_USER':
      return null;
    default:
      return state;
  }
};
