import { FC, Dispatch, Fragment, useState, SetStateAction } from "react";
import { styled, TableCell, tableCellClasses, Button } from "@mui/material";
import DataPays from "../../../../interface/DataPays";
import DataBu from "../../../../interface/DataBu";
import DataClient from "../../../../interface/DataClient";
import DataOperation from "../../../../interface/DataOperation";
import DataFamilleOperation from "../../../../interface/DataFamilleOperation";
import CustomModal from "../../../../commons/custom-modal/CustomModal";
import OperationForm from "../../../forms/OperationForm";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SocieteFact from "../../../../interface/SocieteFact";

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#777",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface OperationRowProps {
  operation: DataOperation;
  allPays: DataPays[];
  allBus: DataBu[];
  allClients: DataClient[];
  allFamilleOperations: DataFamilleOperation[];
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  allSocieteFact: SocieteFact[];
}

const OperationRow: FC<OperationRowProps> = ({
  operation,
  allPays,
  allBus,
  allClients,
  allFamilleOperations,
  refresh,
  setRefresh,
  allSocieteFact,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const codeFamille =
    operation?.idFamille &&
    allFamilleOperations?.find(
      (famille) => famille?.id === operation?.idFamille
    )?.codeFamille;
  return (
    <Fragment>
      <StyledTableCell align="center">{operation?.pays}</StyledTableCell>
      <StyledTableCell align="center">{operation?.bu}</StyledTableCell>
      <StyledTableCell align="center">{operation?.client}</StyledTableCell>
      <StyledTableCell align="center">{operation?.operation1}</StyledTableCell>
      <StyledTableCell align="center">{operation?.active}</StyledTableCell>
      <StyledTableCell align="center">{codeFamille}</StyledTableCell>
      <StyledTableCell align="center">{operation?.codeArticle}</StyledTableCell>
      <StyledTableCell align="center">
        {
          allSocieteFact.find((s) => s.id === operation?.idSocieteFacturation)
            ?.libelle
        }
      </StyledTableCell>
      <StyledTableCell align="center">
        <Button
          color="primary"
          size="small"
          variant="outlined"
          sx={{ textTransform: "none" }}
          startIcon={<EditIcon />}
          onClick={() => setOpenModal(true)}
        >
          Modifier
        </Button>
      </StyledTableCell>

      <CustomModal
        title="Modifier une opération"
        onClose={handleClose}
        open={openModal}
      >
        <OperationForm
          operation={operation}
          allPays={allPays}
          allBus={allBus}
          allClients={allClients}
          allFamilleOperations={allFamilleOperations}
          setOpenModal={setOpenModal}
          setRefresh={setRefresh}
          refresh={refresh}
          allSociete={allSocieteFact}
        />
      </CustomModal>
    </Fragment>
  );
};
export default OperationRow;
