import { styled, alpha } from '@mui/material/styles';
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router";
import Menu, { MenuProps } from '@mui/material/Menu';
import { Stack, Divider, MenuItem, Button, Typography, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Cookies from 'universal-cookie';
import { Reducers } from '../../../src/redux/reducers';
import { useSelector } from 'react-redux';

const saisie = process.env.REACT_APP_SAISIE_URL as string;
const production = process.env.REACT_APP_PRODUCTION_URL as string;
const supervision = process.env.REACT_APP_SUPERVISION_URL as string;
const flash = process.env.REACT_APP_FLASH_URL as string;
const tableau = process.env.REACT_APP_TABLEAU_URL as string;
const flashTab = process.env.REACT_APP_TABLEAU_FLASH_URL as string;
const historiquePrev = process.env.REACT_APP_HISTORIQUE_PREV_URL as string;
const extractionPrev = process.env.REACT_APP_EXTRACTION_PREV_FLASH_URL as string;

const joursOuvres = process.env.REACT_APP_JOURS_OUVRES_URL as string;
const utilisateur = process.env.REACT_APP_UTILISATEURS_URL as string;
const saisieOperation = process.env.REACT_APP_SAISIE_OPERATIONS_URL as string;
const managerEnv = process.env.REACT_APP_MANAGER_ASPX_URL as string;
const affectationManagerEnv = process.env.REACT_APP_SAISIE_AFFECT_MANAGER_URL as string;
const previsionnelEnv = process.env.REACT_APP_SAISIE_PREV_ASPX_URL as string;
const previsionnelHistoriqueEnv = process.env.REACT_APP_SAISIE_PREV_HIST_ASPX_URL as string;
const previsionnelHistoriqueEnvAdmin = process.env.REACT_APP_ADMIN_PREV_HIST as string;

const paysEnv = process.env.REACT_APP_PAYS as string;
const buEnv = process.env.REACT_APP_BUS as string;
const clientEnv = process.env.REACT_APP_CLIENTS as string;
const paysFactEnv = process.env.REACT_APP_PAYS_FACTURATION as string;
const societeFactEnv = process.env.REACT_APP_SOCIETE_FACTURATION as string;
const tarificationEnv = process.env.REACT_APP_TARIF as string;
const coutEnv = process.env.REACT_APP_COUT as string;
const budgetEnv = process.env.REACT_APP_BUDGET as string;

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: 13,
  fontFamily: ['Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ContentNavbar = () => {
  const urlLogin = process.env.REACT_APP_LOGIN_URL as string;

  const logger = useSelector<Reducers>((state) => state.logger);

  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const openMenu1 = Boolean(anchorEl1);

  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const openMenu2 = Boolean(anchorEl2);

  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const openMenu3 = Boolean(anchorEl3);

  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null);
  const openMenu4 = Boolean(anchorEl4);

  const [anchorEl5, setAnchorEl5] = useState<null | HTMLElement>(null);
  const openMenu5 = Boolean(anchorEl5);

  const [anchorEl6, setAnchorEl6] = useState<null | HTMLElement>(null);
  const openMenu6 = Boolean(anchorEl6);

  const [anchorEl7, setAnchorEl7] = useState<null | HTMLElement>(null);
  const openMenu7 = Boolean(anchorEl7);

  const [anchorEl8, setAnchorEl8] = useState<null | HTMLElement>(null);
  const openMenu8 = Boolean(anchorEl8);

  const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const handleClick4 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const handleClick5 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  const handleClick6 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl6(event.currentTarget);
  };
  const handleClose6 = () => {
    setAnchorEl6(null);
  };

  const handleClick7 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl7(event.currentTarget);
  };
  const handleClose7 = () => {
    setAnchorEl7(null);
  };

  const handleClick8 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl8(event.currentTarget);
  };
  const handleClose8 = () => {
    setAnchorEl8(null);
  };

  const history = useHistory();

  const handleClick = (url) => {
    setAnchorEl1(null);
    history.push(url);
  };

  const handleDeconnect = () => {
    const cookies = new Cookies();

    cookies.remove('user_info');

    setTimeout(() => {
      window.location.assign(urlLogin);
    }, 1000);
  };

  const menuItems = [
    {
      text: 'Deconnexion',
      click: handleDeconnect,
      bgColor: '#e7e7e7'
    },
    {
      text: 'Paramètrage',
      click: handleClick1,
      controls: 'parametrage-menu',
      expanded: openMenu1,
      icon: <KeyboardArrowDownIcon />,
      labelledby: 'parametrage-menu-button',
      anchor: anchorEl1,
      open: openMenu1,
      close: handleClose1,
      menu: [
        {
          label: 'Pays',
          url: () => window.location.assign(paysEnv)
        },
        {
          label: 'BUs',
          url: () => window.location.assign(buEnv)
        },
        {
          label: 'Clients',
          path: '/parametrage/client'
        },
        {
          label: 'Opérations',
          path: '/parametrage/operation'
        },
        {
          label: 'Famille Operation',
          path: '/parametrage/famille/operation'
        },
        {
          label: 'Pays de facturation',
          url: () => window.location.assign(paysFactEnv)
        },
        {
          label: 'Société de facturation',
          url: () => window.location.assign(societeFactEnv)
        },
        {
          label: 'Jours Ouvrés',
          url: () => window.location.assign(joursOuvres)
        },
        {
          label: 'Utilisateurs',
          url: () => window.location.assign(utilisateur),
          divider: <Divider sx={{ my: 0.5 }} />
        },
        {
          label: 'Administrations',
          disable: true
        },
        {
          label: 'Heures des Agents',
          path: '/production/saisie/manager'
        },
        {
          label: 'Suivi saisie des opérations',
          url: () => window.location.assign(saisieOperation)
        }
      ]
    },
    {
      text: 'Tarifs & Coûts',
      click: handleClick2,
      controls: 'tarif-menu',
      expanded: openMenu2,
      icon: <KeyboardArrowDownIcon />,
      labelledby: 'tarif-menu-button',
      anchor: anchorEl2,
      open: openMenu2,
      close: handleClose2,
      menu: [
        {
          label: 'Tarifs',
          url: () => window.location.assign(tarificationEnv)
        },
        {
          label: 'Coûts',
          url: () => window.location.assign(coutEnv)
        }
      ]
    },
    {
      text: 'Managers',
      click: handleClick3,
      controls: 'manager-menu',
      expanded: openMenu3,
      icon: <KeyboardArrowDownIcon />,
      labelledby: 'manager-menu-button',
      anchor: anchorEl3,
      open: openMenu3,
      close: handleClose3,
      menu: [
        {
          label: 'Managers',
          url: () => window.location.assign(managerEnv)
        },
        {
          label: 'Managers par opérations',
          url: () => window.location.assign(affectationManagerEnv)
        }
      ]
    },
    {
      text: 'Production',
      click: handleClick4,
      controls: 'production-menu',
      expanded: openMenu4,
      icon: <KeyboardArrowDownIcon />,
      labelledby: 'production-menu-button',
      anchor: anchorEl4,
      open: openMenu4,
      close: handleClose4,
      menu: [
        {
          label: 'Heures des Agents',
          path: '/production/saisie/manager',
          divider: <Divider sx={{ my: 0.5 }} />
        },
        {
          label: 'Gestion',
          path: '',
          disable: true
        },
        {
          label: 'Heures des Agents',
          path: '/production/saisie/manager'
        },
        {
          label: 'Administration',
          path: '',
          disable: true
        },
        {
          label: 'Heures des Agents',
          path: '/production/saisie/manager'
        }
      ]
    },
    {
      text: 'Prévisionnel',
      click: handleClick5,
      controls: 'previsionnel-menu',
      expanded: openMenu5,
      icon: <KeyboardArrowDownIcon />,
      labelledby: 'previsionnel-menu-button',
      anchor: anchorEl5,
      open: openMenu5,
      close: handleClose5,
      menu: [
        {
          label: 'Prévisionnel par opération',
          url: () => window.location.assign(previsionnelEnv)
        },
        {
          label: 'Historique',
          url: () => window.location.assign(previsionnelHistoriqueEnv),
          divider: <Divider sx={{ my: 0.5 }} />
        },
        {
          label: 'Administration',
          path: '',
          disable: true
        },
        {
          label: 'Historique',
          url: () => window.location.assign(previsionnelHistoriqueEnvAdmin)
        }
      ]
    },
    {
      text: 'Budget',
      click: handleClick6,
      controls: 'budget-menu',
      expanded: openMenu6,
      icon: <KeyboardArrowDownIcon />,
      labelledby: 'budget-menu-button',
      anchor: anchorEl6,
      open: openMenu6,
      close: handleClose6,
      menu: [
        {
          label: 'Budget par client',
          url: () => window.location.assign(budgetEnv)
        }
      ]
    },
    {
      text: 'Extraction',
      click: handleClick7,
      controls: 'extraction-menu',
      expanded: openMenu7,
      icon: <KeyboardArrowDownIcon />,
      labelledby: 'extraction-menu-button',
      anchor: anchorEl7,
      open: openMenu7,
      close: handleClose7,
      menu: [
        {
          label: 'Saisie',
          url: () => window.location.assign(saisie)
        },
        {
          label: 'Renta clients',
          url: () => window.location.assign(production)
        },
        {
          label: 'Coûts de Supervision',
          url: () => window.location.assign(supervision)
        },
        {
          label: 'Flash',
          url: () => window.location.assign(flash)
        },
        {
          label: 'Tableau SI',
          url: () => window.location.assign(tableau)
        },
        {
          label: 'Tableau SI FLASH',
          url: () => window.location.assign(flashTab)
        },
        {
          label: 'Historique Prévisionnel',
          url: () => window.location.assign(historiquePrev)
        },
        {
          label: 'Extraction Prévisionnel',
          url: () => window.location.assign(extractionPrev)
        }
      ]
    }
    ,
    {
      text: 'Facturation',
      click: handleClick8,
      controls: 'extraction-menu',
      expanded: openMenu8,
      icon: <KeyboardArrowDownIcon />,
      labelledby: 'extraction-menu-button',
      anchor: anchorEl8,
      open: openMenu8,
      close: handleClose8,
      menu: [
        {
          label: 'Generer Facture',
          path: '/facture'
        }
      ]
    }
  ];

  const connectedUser = (logger as any)?.login;

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      <Stack style={{ width: "100%" }}>
        <Stack direction="row" style={{ width: "100%", position: "relative" }}>
          <Typography variant="h6" color="#777">
            Suivi Rentabilité
          </Typography>
          <Typography
            sx={{ mt: 1, fontSize: "14px", position: "absolute", right: "1%" }}
            color="#777"
          >
            Bonjour <em style={{ color: "#E75012" }}>{connectedUser}</em>
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          style={{ width: "100%", marginTop: "10px" }}
        >

          {menuItems.map(item => (
            <Fragment>
              <Button
                size="small"
                aria-controls={item.controls && item.controls}
                aria-haspopup="true"
                aria-expanded={item.expanded ? "true" : undefined}
                onClick={item.click}
                endIcon={item.icon && item.icon}
                sx={{ mb: 0, color: "#777", textTransform: "none", backgroundColor: item.bgColor && item.bgColor }}
              >
                {item.text}
              </Button>
              {
                item.menu && (
                  <StyledMenu
                    id={item.controls}
                    MenuListProps={{
                      "aria-labelledby": item.labelledby,
                    }}
                    anchorEl={item.anchor}
                    open={item.open}
                    onClose={item.close}
                  >
                    {item.menu.map(index => (
                      <>
                        <StyledMenuItem onClick={index.path ? () => handleClick(index.path) : index.url} disabled={index.disable && index.disable} disableRipple>
                          {index.label}
                        </StyledMenuItem>
                        {index.divider && index.divider}
                      </>
                    ))}
                  </StyledMenu>
                )
              }
            </Fragment>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ContentNavbar;

