import './App.css';
import Navbar from './components/header/Navbar';
import Logo from './components/header/Logo';
import Routes from './router';

function App() {
  return (
    <div className="App">
      <Logo />
      <Navbar />
      <Routes />
    </div>
  );
}

export default App;
