import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import logoOutsourcia from '../../images/banniere_outsourcia2.png';

const useStyle = makeStyles((theme: Theme) => createStyles({
    img: {
        width: '100%',
        height: 'auto',
        marginTop: '0px',
        padding: '0px',
        position: 'relative'
    }
   
}));

const Logo = () => {
    const style = useStyle();
  return (
    <Box>
      <img
        className={style.img}
        src={logoOutsourcia}
        alt=" logo du groupe outsourcia"
      />
    </Box>
  );
};
export default Logo;
