import { useState, Dispatch, FC, Fragment, SetStateAction } from "react";
import { createStyles, makeStyles, Theme, InputLabel } from '@material-ui/core';
import { Reducers } from "../../redux/reducers";
import { useSelector } from 'react-redux';
import { Box, FormControl, Grid } from '@material-ui/core';
import { Button, CircularProgress, TextField, Select } from '@mui/material';
import CheckAttr from "../../function/CheckAttr";
import SaveIcon from '@mui/icons-material/Save';
import { Alert } from '@material-ui/lab';
import DataRef from "../../interface/DataRef";
import DataSocieteFacturation from "../../interface/DataSocieteFacturation";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        formControl: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        TextField: {
            width: '100%',
        },
        warning: {
            color: theme.palette.primary.light,
        },
        loading: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            }
        },
        messageWarning: {
            color: 'red',
            display: 'flex',
            alignItems: 'center',
        },
    })
);

const showMessageToastError = (): void => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const showMessageToastSuccess = (msg: string): void => {
    toast.success(msg,
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
};

const showMessageWarning = (param: string) => {
    toast.warning(param, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

interface SocieteFacturationFormProps {
    allPaysFacturations: DataRef[];
    societeFacturation?: DataSocieteFacturation;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    refresh: boolean;
};

const SocieteFacturationForm: FC<SocieteFacturationFormProps> = ({
    allPaysFacturations,
    societeFacturation,
    setOpenModal,
    setRefresh,
    refresh
}) => {
    const classes = useStyles();

    const urlApi = process.env.REACT_APP_BACKEND_URL as string;
    const logger = useSelector<Reducers>((state) => state.logger);

    const [isClickSave, setIsClickSave] = useState<boolean>(false);
    const [societeFacturationToSave, setSocieteFacturationToSave] = useState<DataSocieteFacturation | undefined>(societeFacturation);
    const [errorInput, setErrorInput] = useState<any>({
        libelle: false,
        pays_facturation_id: false
    });

    const handleChange = (e: any): void => {
        const { name, value } = e.target;

        setSocieteFacturationToSave({
            ...societeFacturationToSave as DataSocieteFacturation,
            [name]: value
        });
    };

    const resetAllRequiredInput = (): void => {
        setErrorInput((prev: any) => ({
            ...prev,
            libelle: false,
            pays_facturation_id: false
        }));
    }

    const handleSave = (): void => {
        // reinitialiser à false les valeurs des attributs qui controle les champs obligatoire
        resetAllRequiredInput();

        // Control de tout les champs obligatoire
        if (!CheckAttr(societeFacturationToSave?.libelle) || !CheckAttr(societeFacturationToSave?.pays_facturation_id)) {
            if (!CheckAttr(societeFacturationToSave?.libelle)) {
                setErrorInput((prev: any) => ({ ...prev, libelle: true }));
                showMessageWarning("Merci de remplir le libelle du société de facturation !");
            }
            if (!CheckAttr(societeFacturationToSave?.pays_facturation_id)) {
                setErrorInput((prev: any) => ({ ...prev, pays_facturation_id: true }));
                showMessageWarning("Merci de remplir le pays de facturation correspondant au société de facturation !");
            }
        } else {
            setIsClickSave(true);

            if (societeFacturationToSave?.id) {
                // update Société de facturation
                axios.put(`${urlApi}parametrage/societefacturation/update`, societeFacturationToSave)
                    .then(res => {
                        showMessageToastSuccess(res.data);
                        setOpenModal(false);
                        setRefresh(!refresh);
                    }).catch((e) => {
                        alert(e.message);
                        showMessageToastError();
                    }).finally(() => {
                        setIsClickSave(false);
                    });
            } else {
                //  create new Société de facturation
                axios.post(`${urlApi}parametrage/societefacturation/save`, societeFacturationToSave)
                    .then((res) => {
                        showMessageToastSuccess(res.data);
                        setOpenModal(false);
                        setRefresh(!refresh);
                    }).catch((e) => {
                        alert(e.message);
                        showMessageToastError();
                    }).finally(() => {
                        setIsClickSave(false);
                    });
            }
        }
    };

    const societFactLabel = (
        <Fragment>
            <span>Société de facturation</span>
            <span style={{ color: 'red', fontSize: 20 }}> *</span>
        </Fragment>
    );

    /**
    * Logique show message validarion forms........................
    */
    const messageValidation =
        !societeFacturationToSave?.libelle ||
        !societeFacturationToSave?.pays_facturation_id && (
            <Alert variant="filled" severity="error">
                Attention tous les champs en <b>*</b> rouge sont obligatoire, merci de
                remplir SVP !
            </Alert>
        );

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label={societFactLabel}
                            name="libelle"
                            color="warning"
                            error={errorInput?.libelle}
                            value={societeFacturationToSave?.libelle}
                            autoComplete="none"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.libelle !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel
                            htmlFor="pays_facturation_id"
                            shrink={societeFacturationToSave?.pays_facturation_id !== undefined && true}
                        >
                            Pays de facturation{' '}
                            <span style={{ color: 'red', fontSize: 20 }}>*</span>
                        </InputLabel>
                        <Select
                            value={societeFacturationToSave?.pays_facturation_id}
                            onChange={handleChange}
                            error={errorInput?.pays_facturation_id}
                            label="Pays de facturation"
                            color="warning"
                            inputProps={{
                                name: 'pays_facturation_id',
                            }}
                            native
                        >
                            <option value=""></option>
                            {allPaysFacturations.map((item) => (
                                <option key={item?.id} value={item?.id}>
                                    {item?.libelle}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label='Activité'
                            name="activite"
                            color="warning"
                            value={societeFacturationToSave?.activite}
                            autoComplete="none"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.activite !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label='Forme Juridique'
                            name="forme_juridique"
                            color="warning"
                            value={societeFacturationToSave?.forme_juridique}
                            autoComplete="none"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.forme_juridique !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label='Capital'
                            name="capital"
                            color="warning"
                            value={societeFacturationToSave?.capital}
                            autoComplete="none"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.capital !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label='Adresse'
                            name="adresse"
                            color="warning"
                            value={societeFacturationToSave?.adresse}
                            autoComplete="new-password"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.adresse !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label='Code postal'
                            name="code_postal"
                            color="warning"
                            value={societeFacturationToSave?.code_postal}
                            autoComplete="new-password"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.code_postal !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label='Ville'
                            name="ville"
                            color="warning"
                            value={societeFacturationToSave?.ville}
                            autoComplete="new-password"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.ville !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label='Registre commerce'
                            name="registre_commerce"
                            color="warning"
                            value={societeFacturationToSave?.registre_commerce}
                            autoComplete="none"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.registre_commerce !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label='Identifiant fiscal'
                            name="identifiant_fiscal"
                            color="warning"
                            value={societeFacturationToSave?.identifiant_fiscal}
                            autoComplete="none"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: societeFacturationToSave?.identifiant_fiscal !== undefined && true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt={3}>
                <Button
                    color="warning"
                    size="large"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    sx={{ textTransform: 'none' }}
                    onClick={handleSave}
                >
                    {!isClickSave ? (
                        'Enregistrer'
                    ) : (
                        <CircularProgress color="inherit" size={18} />
                    )}
                </Button>
            </Box>{' '}
            <br />
            <div>{messageValidation}</div>
        </Box>
    );
};

export default SocieteFacturationForm;
