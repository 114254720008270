import { makeStyles } from "@material-ui/styles";

// Style css for page Saisie acte
export const useStyles = makeStyles({
    root: {
      width: "100%",
      marginTop: "20px",
      paddingLeft: "20%",
      paddingRight: "20%",
    },
    fieldSet: {
      backgroundColor: "#eeeeee",
      padding: "20px",
      borderRadius: "5px",
    },
    formControl: {
      width: "100%",
      backgroundColor: "white",
    },
    textField: {
      width: "100%",
      backgroundColor: "white",
    },
    legend: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    butonImport: {
      backgroundColor: "#e75113",
      marginTop: "10px",
      marginLeft: "15px",
    },
  });