import { FC, Dispatch, Fragment, useState, useCallback, SetStateAction } from "react";
import {
    styled,
    Box,
    Button,
    Stack,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    CircularProgress
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DataRef from "../../../../interface/DataRef";
import DataSocieteFacturation from "../../../../interface/DataSocieteFacturation";
import CustomModal from "../../../../commons/custom-modal/CustomModal";
import SocieteFacturationForm from "../../../forms/SocieteFacturationForm";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const showMessageToastError = (): void => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    });
};

const showMessageToastSuccess = (msg: string): void => {
    toast.success(msg,
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
};

interface PaysFacturationRowProps {
    societeFacturation: DataSocieteFacturation;
    allPaysFacturations: DataRef[];
    refresh: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>,
    setPage: Dispatch<SetStateAction<number>>
}

const SocieteFacturationRow: FC<PaysFacturationRowProps> = ({
    societeFacturation,
    allPaysFacturations,
    refresh,
    setRefresh,
    setPage
}) => {
    const urlApi = process.env.REACT_APP_BACKEND_URL as string;

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [isClickDelete, setIsClickDelete] = useState<boolean>(false);

    const paysFacturation: string | undefined = allPaysFacturations?.find(paysFact => paysFact?.id === societeFacturation?.pays_facturation_id)?.libelle;

    const handleClickDelete = useCallback(() => {
        setIsClickDelete(true);
        axios.delete(`${urlApi}parametrage/societefacturation/delete/${societeFacturation?.id}`)
            .then((res) => {
                setOpenDeleteDialog(false);
                setPage(1);
                setRefresh(!refresh);
                showMessageToastSuccess("Suppression effectué !");
            })
            .catch((e) => {
                showMessageToastError();
            }).finally(() => {
                setIsClickDelete(false);
            });
    }, []);

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <Fragment>
            <StyledTableCell align="center">
                <div style={{ width: '150px' }}>{societeFacturation?.libelle}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div style={{ width: '150px' }}>{paysFacturation}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div style={{ width: '250px' }}>{societeFacturation?.activite}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                {societeFacturation?.forme_juridique}
            </StyledTableCell>
            <StyledTableCell align="center">
                <div style={{ width: '100px' }}>{societeFacturation?.capital}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div style={{ width: '150px' }}>{societeFacturation?.adresse}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                {societeFacturation?.code_postal}
            </StyledTableCell>
            <StyledTableCell align="center">
                {societeFacturation?.ville}
            </StyledTableCell>
            <StyledTableCell align="center">
                <div style={{ width: '150px' }}>{societeFacturation?.registre_commerce}</div>
            </StyledTableCell>
            <StyledTableCell align="center">
                {societeFacturation?.identifiant_fiscal}
            </StyledTableCell>
            <StyledTableCell>
                <Stack direction='row' spacing={1}>
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        startIcon={<EditIcon />}
                        onClick={() => setOpenModal(true)}
                    >
                        Modifier
                    </Button>
                    <Button
                        color="error"
                        size="small"
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        startIcon={<DeleteForeverIcon />}
                        onClick={() => setOpenDeleteDialog(true)}
                    >
                        Supprimer
                    </Button>
                </Stack>
            </StyledTableCell>

            <CustomModal
                title="Modifier une société de facturation"
                onClose={handleClose}
                open={openModal}
            >
                <SocieteFacturationForm
                    allPaysFacturations={allPaysFacturations}
                    societeFacturation={societeFacturation}
                    setOpenModal={setOpenModal}
                    setRefresh={setRefresh}
                    refresh={refresh}
                />
            </CustomModal>

            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Vous êtes sur le point de supprimer ce société de facturation.
                        <Box justifyContent="center" display="flex" mb={-2} >
                            Êtes-vous sûr de vouloir poursuivre cette action ?
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="warning"
                        onClick={() => handleClickDelete()}
                        autoFocus
                    >
                        {isClickDelete ? <CircularProgress color="inherit" size={16} /> : 'Oui'}
                    </Button>
                    <Button color="inherit" onClick={() => setOpenDeleteDialog(false)}>
                        Non
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default SocieteFacturationRow;
