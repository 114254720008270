import { useEffect } from 'react';
import { loggingUser } from '../../redux/actions/logginUser';
import { UserInformation } from '../../interface/UserInformation';
import { Stack, LinearProgress } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const urlLogin = process.env.REACT_APP_LOGIN_URL as string;
const urlApi = process.env.REACT_APP_BACKEND_C_SHARPS_URL as string;

// intercept the resonse of all axios requests
axios.interceptors.response.use(
    function myFunctionResponse(response) {
        return response;
    },
    function myFunctionError(error) {
        if (error) {
            if (error?.response?.status === 401) {
                /**
                 * if there is a response which has a status code 401
                 * after the code redirect it to the login page of CRM
                 **/
                // window.location.assign(urlLogin);
            }
            return Promise.reject(error);
        }
    }
);

const EntryPoint = () => {
    const { token } = useParams<{ token }>();

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        const tokenVal = String(token).replace(/\"/g, '');

        if (tokenVal) {
            // get all user Information
            axios.post(`${urlApi}api/login/user/info`, { token: tokenVal })
                .then((resultat) => {
                    console.log(resultat.data);
                    const user: UserInformation = { ...resultat.data, token: tokenVal };

                    if (user) {
                        const cookies = new Cookies();

                        // Set info user in navigateur coockies
                        cookies.set('user_info', user, { path: '/' });

                        // Get info user via coockies navigateur
                        const userInformation: UserInformation = cookies.get('user_info');

                        /**
                         * save in Redux store the user information with his token
                         * New code fait par Antoni
                         */
                        if (userInformation) {
                            dispatch(
                                loggingUser({
                                    token: userInformation.token,
                                    profil: userInformation?.profil,
                                    departement: userInformation?.departement,
                                    login: userInformation?.login,
                                    name: userInformation?.name,
                                    firstname: userInformation?.firstname
                                })
                            );

                            // Redirect to saisie manager page
                            history.push('/production/saisie/manager');
                        } else {
                            window.location.assign(urlLogin);
                        }
                    }
                }).catch((error) => {
                    console.log(error.message);
                    window.location.assign(urlLogin);
                });
        } else {
            window.location.assign(urlLogin);
        }
    }, []);

    return (
        <Stack spacing={2} mt={6}>
            <LinearProgress color='inherit' />
            <LinearProgress color='warning' />
        </Stack>
    );
};

export default EntryPoint;
