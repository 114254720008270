import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useStyles } from "./styles";
import axios from "axios";
import { Reducers } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

export interface Client {
  id: number;
  raisonSocial: string;
}

export interface Operation {
  id: number;
  libelle: string;
}

export interface LigneFacturation {
  id: number;
  operation: Operation;
}

export interface Bdc {
  id: number;
  bdcOperations: LigneFacturation[];
  resumeLead: {
    id: number;
    customer: {
      id: number;
      raisonSocial: string;
    };
  };
}

export interface Data {
  client: number;
  operations: number;
  acte: string;
  dateDebut: string;
  dateFin: string;
}

const SaisieActe = () => {
  const urlApi = process.env.REACT_APP_BACKEND_URL as string;

  const logger = useSelector<Reducers>((state) => state.logger);

  const classes = useStyles();
  const [data, setData] = useState<Data | any>();
  const [customer, setCustomer] = useState<Client[]>([]);
  const [operationBdcEnProd, setOperationBdcEnProd] = useState<Bdc[]>([]);

  // Logique affichage operations
  const tabOperations = [] as any;
  if (data?.client && operationBdcEnProd) {
    for (const i in operationBdcEnProd) {
      for (const j in operationBdcEnProd[i]?.bdcOperations) {
        if (
          Number(data?.client) ===
          Number(operationBdcEnProd[i]?.resumeLead?.customer?.id)
        ) {
          tabOperations.push(
            operationBdcEnProd[i]?.bdcOperations[j]?.operation
          );
        }
      }
    }
  }

  // Supprimer les doublons dans le tableau tabOperations
  function removeDuplicates() {
    const newArray = [] as any;
    const uniqObject = {};
    for (const k in tabOperations) {
      // Extract the id
      const objId = tabOperations[k]["id"];

      // Use the id as the index
      uniqObject[objId] = tabOperations[k];
    }
    for (const l in uniqObject) {
      newArray.push(uniqObject[l]);
    }

    return newArray;
  }

  useEffect(() => {
    // get all list cutomers
    axios
      .get(`${urlApi}find/all/customers`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    // get operations du bdc en production par rapport au client choisi
    axios
      .get(`${urlApi}find/bdc/en/prod`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      }).then((res) => {
        Array.isArray(res.data) && setOperationBdcEnProd(res.data);
      }).catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Box className={classes.root}>
      <fieldset className={classes.fieldSet}>
        <legend className={classes.legend}>
          <Typography variant="h4" color="#8E8B8A">
            Saisie Acte
          </Typography>
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="select_client">Client</InputLabel>
              <Select
                native
                name="client"
                value={data?.client || ""}
                onChange={handleChangeInput}
                label="Client"
              >
                <option value=""></option>
                {(customer || [])?.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.raisonSocial}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="select_operation">Opérations</InputLabel>
              <Select
                native
                name="operation"
                value={data?.operations}
                onChange={handleChangeInput}
                label="Opérations"
              >
                <option value=""></option>
                {(removeDuplicates() || [])?.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.libelle}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              label="Acte"
              name="acte"
              type="number"
              placeholder="Saisie acte..."
              value={data?.acte || ""}
              onChange={handleChangeInput}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="dateDebut"
              className={classes.textField}
              label="Date début"
              value={data?.dateDebut || ""}
              onChange={handleChangeInput}
              type="date"
              defaultValue="mm/jj/aa"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="dateFin"
              className={classes.textField}
              label="Date fin"
              value={data?.dateFin || ""}
              onChange={handleChangeInput}
              type="date"
              defaultValue="mm/jj/aa"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Box className={classes.butonImport}>
            <Button
              variant="outlined"
              style={{ color: "white" }}
              startIcon={<ImportExportIcon />}
            >
              Import excel
            </Button>
          </Box>
        </Grid>
      </fieldset>
    </Box>
  );
};

export default SaisieActe;
