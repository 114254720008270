import { FC, Dispatch, Fragment, useState, SetStateAction } from "react";
import { styled, TableCell, tableCellClasses, Button } from '@mui/material';
import DataPays from "../../../../interface/DataPays";
import DataBu from "../../../../interface/DataBu";
import DataClient from "../../../../interface/DataClient";
import CustomModal from "../../../../commons/custom-modal/CustomModal";
import ClientForm from "../../../forms/ClientForm";
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

interface ClientRowProps {
    allPays: DataPays[];
    allBus: DataBu[];
    client: DataClient;
    refresh: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>
}

const ClientRow: FC<ClientRowProps> = ({
    allPays,
    allBus,
    client,
    refresh,
    setRefresh
}) => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <Fragment>
            <StyledTableCell align="center">{client?.pays}</StyledTableCell>
            <StyledTableCell align="center">{client?.bu}</StyledTableCell>
            <StyledTableCell align="center">{client?.client1}</StyledTableCell>
            <StyledTableCell align="center">{client?.code_client_gesco}</StyledTableCell>
            <StyledTableCell align="center">
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    sx={{ textTransform: 'none' }}
                    startIcon={<EditIcon />}
                    onClick={() => setOpenModal(true)}
                >
                    Modifier
                </Button>
            </StyledTableCell>

            <CustomModal
                title="Modifier une opération"
                onClose={handleClose}
                open={openModal}
            >
                <ClientForm
                    client={client}
                    allPays={allPays}
                    allBus={allBus}
                    setOpenModal={setOpenModal}
                    setRefresh={setRefresh}
                    refresh={refresh}
                />
            </CustomModal>
        </Fragment>
    );
};

export default ClientRow;
