import {
  Box,
  Paper,
  Pagination,
  TextField,
  styled,
  Stack,
  Divider,
  LinearProgress,
  Typography,
  Breadcrumbs,
  Grid,
  Button,
  TableHead,
  TableRow,
  InputAdornment,
  TableBody,
  TableContainer,
  Table,
  Container,
  FormControl,
  Select,
  IconButton,
  InputLabel,
  MenuItem,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import CheckAttr from "../../../../function/CheckAttr";
import DataOperation from "../../../../interface/DataOperation";
import GradingIcon from "@mui/icons-material/Grading";
import { Add } from "@material-ui/icons";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { Reducers } from "../../../../redux/reducers";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataPays from "../../../../interface/DataPays";
import DataBu from "../../../../interface/DataBu";
import DataClient from "../../../../interface/DataClient";
import FamilleOperation from "../../../../interface/FamilleOperation";
import CustomModal from "../../../../commons/custom-modal/CustomModal";
import OperationForm from "../../../forms/OperationForm";
import OperationRow from "./OperationRow";
import SocieteFact from "../../../../interface/SocieteFact";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#777",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const showMessageWarning = (param: string) => {
  toast.warning(param, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

toast.configure();

const Operation = () => {
  const urlApi = process.env.REACT_APP_BACKEND_URL as string;
  const urlCSharp = process.env.REACT_APP_BACKEND_C_SHARPS_URL as string;

  const logger = useSelector<Reducers>((state) => state.logger);

  const [filter, setFilter] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [totalData, setTotalData] = useState<number>(0);
  const [dataOperations, setDataOperations] = useState<DataOperation[]>([]);
  const [dataSocieteFact, setDataSocieteFact] = useState<SocieteFact[]>([]);
  const [allPays, setAllPays] = useState<DataPays[]>([]);
  const [allBus, setAllBus] = useState<DataBu[]>([]);
  const [allClients, setAllClients] = useState<DataClient[]>([]);
  const [allFamilleOperations, setAllFamilleOperation] = useState<
    FamilleOperation[]
  >([]);
  const [errorSearch, setErrorSearchInput] = useState<any>({
    filter: false,
    keyword: false,
  });

  const data = {
    filter: CheckAttr(filter) ? filter : "empty",
    keyWord: CheckAttr(keyword) ? keyword : "empty",
    rowPerPage: rowsPerPage,
    page: page,
    token: (logger as any)?.token,
  };

  const receiveData = () => {
    // Activer un loader dans notre page
    setIsLoading(true);
    axios
      .post(`${urlApi}parametrage/operation/list`, data)
      .then((res) => {
        const { totalCount, datas } = res.data;

        if (totalCount > 0) {
          totalCount && setTotalData(totalCount);
          Array.isArray(datas) && setDataOperations(datas);
        } else {
          setTotalData(0);
        }
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    receiveData();
  }, [rowsPerPage, page, refresh]);

  useEffect(() => {
    axios
      .post(`${urlApi}get/ref/data/for/operation-add`, {
        token: (logger as any)?.token,
      })
      .then((res) => {
        const { pays, businessUnits, clients, familleOperations } = res.data;

        Array.isArray(pays) && setAllPays(pays);
        Array.isArray(businessUnits) && setAllBus(businessUnits);
        Array.isArray(clients) && setAllClients(clients);
        Array.isArray(familleOperations) &&
          setAllFamilleOperation(familleOperations);
      })
      .catch((error) => {
        console.log(error.message);
      });

    axios
      .get(`${urlCSharp}api/societe/facturation/all`)
      .then((res) => {
        const datas = res.data;
        Array.isArray(datas) && setDataSocieteFact(datas);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleChange = (e): void => {
    setKeyword(e.target.value);

    if (e.target.value.length === 0) {
      setPage(1);
      setRefresh(!refresh);
    }
  };

  const handleSearch = (): void => {
    // reinitialiser à false les valeurs des attributs qui controle les champs obligatoire
    setErrorSearchInput((prev: any) => ({
      ...prev,
      filter: false,
      keyword: false,
    }));

    if (CheckAttr(filter) && CheckAttr(keyword)) {
      receiveData();
    } else {
      if (!CheckAttr(filter)) {
        setErrorSearchInput((prev: any) => ({ ...prev, filter: true }));
      }
      if (!CheckAttr(keyword)) {
        setErrorSearchInput((prev: any) => ({ ...prev, keyword: true }));
      }
      showMessageWarning("SVP! Merci de remplir les Champs de recherche.");
    }
  };
  const handleAdd = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const pagination: JSX.Element | boolean = !isLoading &&
    totalData > rowsPerPage && (
      <Stack spacing={2} direction="row">
        <InputLabel sx={{ marginTop: "8px" }}>Lignes par page :</InputLabel>
        <FormControl variant="standard" sx={{ minWidth: 40 }}>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            color="warning"
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(e.target.value as number)}
            defaultValue={5}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={Math.ceil(totalData / rowsPerPage)}
          color="standard"
          showFirstButton={true}
          showLastButton={true}
          hideNextButton={true}
          hidePrevButton={true}
          siblingCount={0}
          boundaryCount={1}
          defaultPage={page}
          onChange={(event, value) => setPage(value)}
        />
      </Stack>
    );
  return (
    <Container maxWidth="lg">
      <Box mt={4} mb={2}>
        <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography variant="h5">
                <GradingIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Paramètrage
              </Typography>
              <Typography variant="h6">Opération</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "right" }}
            >
              <FormControl sx={{ minWidth: 220 }}>
                <InputLabel id="filter input" color="warning" sx={{ mt: -1 }}>
                  Chercher par ...
                </InputLabel>
                <Select
                  labelId="filter input"
                  name="filter"
                  error={errorSearch?.filter}
                  value={filter}
                  onChange={(e) => setFilter(e.target.value as string)}
                  size="small"
                  color="warning"
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="pays">Pays de Production</MenuItem>
                  <MenuItem value="bu">Bu</MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="operation">Operation</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ width: "220px" }}
                name="keyword"
                error={errorSearch?.keyword}
                value={keyword}
                onChange={handleChange}
                placeholder="Taper quelque chose ..."
                size="small"
                color="warning"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="search"
                        edge="end"
                        onClick={handleSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Grid container columns={{ xs: 6, sm: 12, md: 12 }}>
        <Grid item xs={6} sm={4} md={4}>
          <Box ml={6}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              color="warning"
              sx={{ textTransform: "none" }}
              onClick={handleAdd}
            >
              Ajouter
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6} sm={8} md={8}>
          <Box justifyContent="right" display="flex" mr={6}>
            {pagination}
          </Box>
        </Grid>
      </Grid>
      <Paper elevation={3}>
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table sx={{ maxHeight: 500 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  Pays de Production
                </StyledTableCell>
                <StyledTableCell align="center">Bu</StyledTableCell>
                <StyledTableCell align="center">Client</StyledTableCell>
                <StyledTableCell align="center">Opération</StyledTableCell>
                <StyledTableCell align="center">Active</StyledTableCell>
                <StyledTableCell align="center">Code Famille</StyledTableCell>
                <StyledTableCell align="center">Code article</StyledTableCell>
                <StyledTableCell align="center">
                  Société de Facturation
                </StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableCell align="center" colSpan={9}>
                  <LinearProgress color="warning" />
                  <Box my={2}>
                    <LinearProgress color="warning" />
                  </Box>
                </StyledTableCell>
              ) : totalData > 0 ? (
                dataOperations.map((operation) => (
                  <StyledTableRow key={operation?.id}>
                    <OperationRow
                      operation={operation}
                      allPays={allPays}
                      allBus={allBus}
                      allClients={allClients}
                      allFamilleOperations={allFamilleOperations}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      allSocieteFact={dataSocieteFact}
                    />
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableCell align="center">
                  Chargement Ou Aucun resultat a été trouvé ...
                </StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box justifyContent="left" display="flex" mb={5} mt={2} ml={6}>
        {pagination}
      </Box>

      <CustomModal
        title="Ajouter une nouvelle opération"
        onClose={handleClose}
        open={openModal}
      >
        <OperationForm
          allPays={allPays}
          allBus={allBus}
          allClients={allClients}
          allFamilleOperations={allFamilleOperations}
          setOpenModal={setOpenModal}
          setRefresh={setRefresh}
          refresh={refresh}
          allSociete={dataSocieteFact}
        />
      </CustomModal>
    </Container>
  );
};

export default Operation;
