import { useState, Fragment } from "react";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import dateFormat from 'dateformat';
import { styled } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomModal from '../../../commons/custom-modal/CustomModal';
import Button from '@mui/material/Button';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModificationBudgetForm from '../../forms/ModificationBudgetForm';

toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#777',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));


const BudgetRow = (props) => {
    const data = props.row;
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleMapiseoCustom = () => {
        setOpen(true);
    }
    return (
        <Fragment>
            <StyledTableCell align="center">
                {data?.pays}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.bu}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.client}
            </StyledTableCell>
            <StyledTableCell align="center">
                {dateFormat(data?.date_debut, 'dd/mm/yyyy')}
            </StyledTableCell>
            <StyledTableCell align="center">
                {dateFormat(data?.date_fin, 'dd/mm/yyyy')}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.ca_budget}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.tx_marge_budget}
            </StyledTableCell>
            <StyledTableCell align="center">
                {data?.marge_valeur_budget}
            </StyledTableCell>
            <StyledTableCell align="center">
                <Button
                    variant="outlined"
                    color="warning"
                    size="small"
                    onClick={handleMapiseoCustom}
                    startIcon={<ModeEditIcon />}>
                    Modifier
                </Button>
            </StyledTableCell>

            <CustomModal title="Modification Budget" onClose={handleClose} open={open}>
                <ModificationBudgetForm
                    budgetRow={data}
                />
            </CustomModal>
        </Fragment>
    )
}
export default BudgetRow;